import { createContext, useContext, useEffect, useState, } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { showSchemaRegistrantDetailRepo } from "../../../../repos/schema/SchemaRegistrantRepo";
import { showSchemaUnitRepo } from "../../../../repos/schema/SchemaUnitRequest";

const SchemaRegistrantDetailBoardContext = createContext();

export const SchemaRegistrantDetailBoardContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const params = useParams();
    const [registrant, setRegistrant] = useState({});
    const [unit, setUnit] = useState([]);

    const getRegistrant = async () => {
        await showSchemaRegistrantDetailRepo({ schema_registrant_id: params['id'] }).then((res) => {
            console.log(res);
            setRegistrant(res);
            getSchemaUnit({ schema_id: res.schema_id });
        });
    }

    const getSchemaUnit = async ({ schema_id }) => {
        await showSchemaUnitRepo({ schema_id: schema_id }).then((res) => {
            console.log(res);
            setUnit(res);
        });
    }

    const onPrint = () => {
        var printContents = document.getElementById("content-app").innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.location.reload();
    }

    useEffect(() => {
        getRegistrant();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SchemaRegistrantDetailBoardContext.Provider value={{ navigation, registrant, unit, onPrint }}>
            {children}
        </SchemaRegistrantDetailBoardContext.Provider>
    );
}

export const UseSchemaRegistrantDetailBoardContext = () => {
    return useContext(SchemaRegistrantDetailBoardContext);
}