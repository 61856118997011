import axios from "axios";
import { API_SCHEMA_REGISTRANT, API_SCHEMA_REGISTRANT_CREATE, API_SCHEMA_REGISTRANT_DETAIL } from "../../configs/api";

export const showSchemaRegistrantRepo = async ({ perpage = 10, page }) => {
    try {
        var param = '';
        page && (param = `&page=${page}`);

        const response = await axios.get(`${API_SCHEMA_REGISTRANT}?perpage=${perpage}${param}`);
        return response.data.data.schema_registrant;
    } catch (error) {
        return false;
    }
}

export const showSchemaRegistrantDetailRepo = async ({ schema_registrant_id }) => {
    try {
        const response = await axios.get(`${API_SCHEMA_REGISTRANT_DETAIL}?id=${schema_registrant_id}`);
        return response.data.data.schema_registrant;
    } catch (error) {
        return false;
    }
}

export const createSchemaRegistrantRepo = async ({ body = {} }) => {
    try {
        const response = await axios.post(`${API_SCHEMA_REGISTRANT_CREATE}`, body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response;
    } catch (error) {
        return error.response;
    }
}