import { createContext, useContext, useEffect, useState, } from "react";
import { useNavigate } from "react-router-dom";
import { showSchemaRepo } from "../../../repos";

const SchemaCertificateContext = createContext();

export const SchemaCertificateContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const [schema, setSchema] = useState({});

    const getSchema = async () => {
        await showSchemaRepo({}).then((res) => {
            setSchema(res);
        });
    }

    useEffect(() => {
        getSchema();
    }, []);

    return (
        <SchemaCertificateContext.Provider value={{ navigation, schema }}>
            {children}
        </SchemaCertificateContext.Provider>
    );
}

export const UseSchemaCertificateContext = () => {
    return useContext(SchemaCertificateContext);
}