import { IcChevronLeft, IcCloudDownload, IcNormalCheck, IcPrint } from "../../../../assets";
import { Card, Content } from "../../../../components/board";
import { InputChecked } from "../../../../components/main";
import { UseSchemaRegistrantDetailBoardContext } from "../../../../contexts/board/schema_certificate/detail/SchemaRegistrantDetailBoardPage";

const SchemaRegistrantDetailBoardPage = () => {
    var no_assesment = 1;
    const { navigation, registrant, unit, onPrint } = UseSchemaRegistrantDetailBoardContext();

    return (
        <Content>
            <div className="flex justify-between items-center print-no-print">
                <div className="flex gap-3 cursor-pointer" onClick={() => navigation(-1)}>
                    <span><IcChevronLeft /></span>
                    <span className="text-base font-semibold">Detail</span>
                </div>
                <Card className="px-2 pt-2 pb-2 overflow-x-auto">
                    <span className="cursor-pointer" onClick={() => onPrint()}>
                        <IcPrint />
                    </span>
                </Card>
            </div>
            <Card className="px-4 pt-4 pb-10 w-full overflow-x-auto mt-4 print:mt-0">
                <span className="font-semibold text-base">Bagian 1 : Rincian Data Pemohon Sertifikasi</span>
                <div className="my-4">
                    <span className="px-2 font-semibold">Data Pribadi</span>
                    <div className="px-4 my-3">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">Nama Lengkap</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.name}</td>
                                </tr>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">No. KTP/NIK/Paspor</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.no_identity}</td>
                                </tr>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">Tempat / tgl. Lahir</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.place_of_birth} / {registrant.date_of_birth}</td>
                                </tr>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">Jenis kelamin</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.gender}</td>
                                </tr>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">Kebangsaan</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.nationality}</td>
                                </tr>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">Alamat rumah</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.address}</td>
                                </tr>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">No. Telepon</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.telp}</td>
                                </tr>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">E-Mail</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.email}</td>
                                </tr>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">Kualifikasi Pendidikan</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.education}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <span className="px-2 font-semibold">Data Pekerjaan Sekarang</span>
                    <div className="px-4 my-3">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">Nama Institusi / Perusahaan</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.schema_registrant_work_model?.company_name}</td>
                                </tr>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">Jabatan</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.schema_registrant_work_model?.position}</td>
                                </tr>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">Alamat Kantor</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.schema_registrant_work_model?.address}</td>
                                </tr>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">No. Telp</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.schema_registrant_work_model?.telp}</td>
                                </tr>
                                <tr>
                                    <td className="pr-10 py-2 w-[12rem]">E-Mail</td>
                                    <td className="pr-2 py-2">:</td>
                                    <td className="py-2">{registrant.schema_registrant_work_model?.email}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Card>
            <Card className="px-4 pt-4 pb-5 w-full overflow-x-auto mt-4 print-pagebreak">
                <span className="font-semibold text-base">Dokumen</span>
                <div className="mt-4">
                    <table className="w-full">
                        <tbody>
                            {registrant?.schema_registrant_document_model?.length === 0 && (
                                <tr>
                                    <td className="border p-3 text-center">Tidak ada data</td>
                                </tr>
                            )}
                            {registrant?.schema_registrant_document_model?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="border p-3">{item.title}</td>
                                        <td className="border p-3 w-[10rem]">
                                            {item.file === '' && (<span>Tidak ada file</span>)}
                                            {item.file !== '' && (<div className="flex gap-3 cursor-pointer hover:text-slate-500" onClick={() => window.open(item.file_decode)}><IcCloudDownload width={18} height={18} /> <span>Unduh</span></div>)}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Card>
            <Card className="px-4 pt-4 pb-5 w-full overflow-x-auto mt-4">
                <span className="font-semibold text-base">Schema</span>
                <div className="mt-1">
                    <span>{registrant?.schema_model?.title}</span>
                </div>
            </Card>
            <Card className="px-4 pt-4 pb-5 w-full overflow-x-auto mt-4">
                <div>
                    <span className="font-semibold text-base">Bagian 2 : Data Sertifikasi</span>
                    <div className="mt-1">
                        <p>
                            Tuliskan Judul dan Nomor Skema Sertifikasi yang anda ajukan berikut Daftar Unit Kompetensi sesuai kemasan pada skema sertifikasi untuk mendapatkan pengakuan sesuai dengan latar belakang pendidikan, pelatihan serta pengalaman kerja yang anda miliki.
                        </p>
                    </div>
                    <div className="w-full mt-2">
                        <table className="w-full">
                            <tbody>
                                <tr>
                                    <td className="border p-3 font-normal" rowSpan={2}>Skema Sertifikasi<br />(KKNI/Okupasi/Klaster)</td>
                                    <td className="border p-3 font-normal">Judul</td>
                                    <td className="border p-3 font-normal">:</td>
                                    <td className="border p-3 font-normal w-full"></td>
                                </tr>
                                <tr>
                                    <td className="border p-3 font-normal">Nomor</td>
                                    <td className="border p-3 font-normal">:</td>
                                    <td className="border p-3 font-normal w-full"></td>
                                </tr>
                                <tr>
                                    <td className="border p-3 font-normal align-top" rowSpan={4} colSpan={2}>Tujuan Assesmen</td>
                                    <td className="border p-3 font-normal text-center">:</td>
                                    <td className="border p-3 font-normal">
                                        <div className="flex items-center gap-2"><InputChecked /> Sertifikasi</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border p-3 font-normal text-center"></td>
                                    <td className="border p-3 font-normal">
                                        <div className="flex items-center gap-2"><InputChecked /> Pengakuan Kompetensi Terkini (PKT)</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border p-3 font-normal text-center"></td>
                                    <td className="border p-3 font-normal">
                                        <div className="flex items-center gap-2"><InputChecked /> Rekognisi Pembelajaran Lampau (RPL)</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border p-3 font-normal text-center"></td>
                                    <td className="border p-3 font-normal">
                                        <div className="flex items-center gap-2"><InputChecked /> Lainnya</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="break-inside-avoid">
                    <div className="my-4">
                        <span className="font-semibold">Daftar Unit Kompetensi sesuai kemasan:</span>
                    </div>
                    <div className="w-full">
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th className="border p-3 font-semibold w-10">No.</th>
                                    <th className="border p-3 font-semibold">Kode Unit</th>
                                    <th className="border p-3 font-semibold">Judul Unit</th>
                                    <th className="border p-3 font-semibold">Standar Kompetensi Kerja</th>
                                </tr>
                            </thead>
                            <tbody>
                                {unit.map((item, index) => {
                                    return <tr key={index}>
                                        <td className="border p-3 text-center">{(index + 1)}.</td>
                                        <td className="border p-3">{item.code}</td>
                                        <td className="border p-3">{item.title}</td>
                                        <td className="border p-3">{item.competency_standards}</td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="break-inside-avoid">
                    <div className="my-4">
                        <span className="font-semibold text-base">Bagian 3 : Bukti Kelengkapan Pemohon</span><br />
                        <span className="font-semibold text-base">3.1 Bukti Persyaratan Dasar Pemohon</span>
                    </div>
                    <div className="w-full">
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th className="border p-3 font-semibold w-10" rowSpan={2}>No.</th>
                                    <th className="border p-3 font-semibold w-full" rowSpan={2}>Bukti Persyaratan Dasar</th>
                                    <th className="border p-3 font-semibold" colSpan={2}>Ada</th>
                                    <th className="border p-3 font-semibold min-w-[5rem] max-w-[5rem] w-20" rowSpan={2}>Tidak Ada</th>
                                </tr>
                                <tr>
                                    <th className="border p-3 font-semibold">Memenuhi Syarat</th>
                                    <th className="border p-3 font-semibold">Tidak Memenuhi Syarat</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border p-3 text-center">1.</td>
                                    <td className="border p-3"></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3"></td>
                                </tr>
                                <tr>
                                    <td className="border p-3 text-center">2.</td>
                                    <td className="border p-3"></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3"></td>
                                </tr>
                                <tr>
                                    <td className="border p-3 text-center">3.</td>
                                    <td className="border p-3"></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3"></td>
                                </tr>
                                <tr>
                                    <td className="border p-3 text-center">4.</td>
                                    <td className="border p-3"></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="break-inside-avoid">
                    <div className="my-4">
                        <span className="font-semibold text-base">3.2 Bukti Administratif</span>
                    </div>
                    <div className="w-full">
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th className="border p-3 font-semibold w-10" rowSpan={2}>No.</th>
                                    <th className="border p-3 font-semibold w-full" rowSpan={2}>Bukti Administratif</th>
                                    <th className="border p-3 font-semibold" colSpan={2}>Ada</th>
                                    <th className="border p-3 font-semibold min-w-[5rem] max-w-[5rem] w-20" rowSpan={2}>Tidak Ada</th>
                                </tr>
                                <tr>
                                    <th className="border p-3 font-semibold">Memenuhi Syarat</th>
                                    <th className="border p-3 font-semibold">Tidak Memenuhi Syarat</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border p-3 text-center">1.</td>
                                    <td className="border p-3"></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3"></td>
                                </tr>
                                <tr>
                                    <td className="border p-3 text-center">2.</td>
                                    <td className="border p-3"></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3"></td>
                                </tr>
                                <tr>
                                    <td className="border p-3 text-center">3.</td>
                                    <td className="border p-3"></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3"></td>
                                </tr>
                                <tr>
                                    <td className="border p-3 text-center">4.</td>
                                    <td className="border p-3"></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3 text-center"><InputChecked /></td>
                                    <td className="border p-3"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-full mt-3 break-inside-avoid">
                    <table className="w-full">
                        <tbody>
                            <tr>
                                <td className="border p-3 min-w-[23rem] max-w-[23rem] w-[23rem]" rowSpan={3}>
                                    <div>
                                        <span className="font-semibold">Rekomendasi (diisi oleh LSP):</span>
                                    </div>
                                    <div className="mt-2">
                                        <span>Berdasarkan ketentuan persyaratan dasar, maka pemohon:</span>
                                    </div>
                                    <div className="mt-2">
                                        <span className="font-semibold">Diterima/ Tidak diterima</span>
                                        <span> {'*)'} sebagai peserta sertifikasi
                                            <b> *</b>coret yang tidak sesuai
                                        </span>
                                    </div>
                                </td>
                                <td className="border p-3 font-semibold" colSpan={2}>
                                    Pemohon/ Kandidat :
                                </td>
                            </tr>
                            <tr>
                                <td className="border p-3">Nama</td>
                                <td className="border p-3 w-full"></td>
                            </tr>
                            <tr>
                                <td className="border p-3">Tanda tangan/ Tanggal</td>
                                <td className="border p-3 w-full"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Card>
            <Card className="px-0 pt-4 pb-5 w-full overflow-x-auto mt-4 break-inside-avoid">
                <div className="px-4 mb-4">
                    <span className="font-semibold text-base">Assesment Mandiri</span>
                </div>
                <div className="w-full">
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="border p-3 font-semibold">No</th>
                                <th className="border p-3 font-semibold">Kode Unit</th>
                                <th className="border p-3 font-semibold">Judul Unit Kompetensi / Elemen Kompetensi / Kriteria Unjuk Kerja(KUK)</th>
                                <th className="border p-3 font-semibold">
                                    <div className="flex flex-col">
                                        <span>K</span>
                                    </div>
                                </th>
                                <th className="border p-3 font-semibold">
                                    <div className="flex flex-col">
                                        <span>BK</span>
                                    </div>
                                </th>
                                <th className="border p-3 font-semibold">Bukti yang relevan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {registrant?.schema_registrant_assesment_model?.length === 0 && (
                                <tr>
                                    <td colSpan={6} className="border p-3 text-center">Tidak ada data</td>
                                </tr>
                            )}
                            {registrant?.schema_registrant_assesment_model?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="border p-3 text-center">{(item.is_heading === 1 && (registrant?.schema_registrant_assesment_model[index - 1]?.is_heading !== 1) && registrant?.schema_registrant_assesment_model[index - 1]?.is_heading !== item.schema_unit_code) && no_assesment++}</td>
                                        <td className="border p-3 text-center">{(item.is_heading === 1 && (registrant?.schema_registrant_assesment_model[index - 1]?.is_heading !== 1) && registrant?.schema_registrant_assesment_model[index - 1]?.is_heading !== item.schema_unit_code) && item.schema_unit_code}</td>
                                        <td className={`border p-3 text-start ${item.is_heading === 1 ? 'font-semibold' : ''}`}>{item.title}</td>
                                        <td className="border p-3 text-start">{(item.is_heading !== 1) && (item.k === 1 && <div className="flex justify-center"><IcNormalCheck width={20} height={20} /></div>)}</td>
                                        <td className="border p-3 text-start">{(item.is_heading !== 1) && (item.bk === 1 && <div className="flex justify-center"><IcNormalCheck width={20} height={20} /></div>)}</td>
                                        <td className="border p-3 text-center">{(item.is_heading !== 1) && <div className="flex justify-center"><div className="max-w-[10rem]">{item.proof}</div></div>}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Card>
            <div className="w-full overflow-x-auto mt-4 break-inside-avoid">
                <table className="w-full">
                    <tbody>
                        <tr>
                            <td className="border p-3" style={{ width: '33.33%' }}>
                                <span className="font-semibold text-sm">Nama Asesi:</span>
                                <div className="h-28"></div>
                            </td>
                            <td className="border p-3" style={{ width: '33.33%' }}>
                                <span className="font-semibold text-sm">Tanggal:</span>
                                <div className="h-28"></div>
                            </td>
                            <td className="border p-3" style={{ width: '33.33%' }}>
                                <span className="font-semibold text-sm">Tanda Tangan Asesi:</span>
                                <div className="h-28"></div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} className="bg-orange-300 font-bold p-2 px-4 print-fill">Ditinjau Oleh Asesor:</td>
                        </tr>
                        <tr>
                            <td className="border p-3" style={{ width: '33.33%' }}>
                                <span className="font-semibold text-sm">Nama Asesor:</span>
                                <div className="h-28">
                                </div>
                            </td>
                            <td className="border p-3" style={{ width: '33.33%' }}>
                                <span className="font-semibold text-sm">Rekomendasi:</span>
                                <div className="h-28">
                                    <span className="text-[13px]">Asesmen dapat dilanjutkan/ tidak dapat dilanjutkan</span>
                                </div>
                            </td>
                            <td className="border p-3" style={{ width: '33.33%' }}>
                                <span className="font-semibold text-sm">Tanda Tangan dan Tanggal:</span>
                                <div className="h-28"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Content>
    );
}

export default SchemaRegistrantDetailBoardPage;