import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";


const Content = (props) => {
    return (
        <div className="flex flex-col h-screen text-[15px] overflow-x-hidden content-parent">
            {props.nav !== false && <Navbar />}
            <div className="grow mb-10">
                {props.children}
            </div>
            <Footer />
            {props.element && props.element}
        </div>
    );
}

export default Content;