import { Route, Routes } from "react-router-dom";
import ProtectedAuth from "./components/auth/protected/ProtectedAuth";
import { LoginContextProvider } from "./contexts/auth/login/LoginContext";
import { SchemaRegistrantDetailBoardContextProvider } from "./contexts/board/schema_certificate/detail/SchemaRegistrantDetailBoardPage";
import { SchemaRegistrantBoardContextProvider } from "./contexts/board/schema_certificate/registrant/SchemaRegistrantBoardContext";
import { MainContextProvider } from "./contexts/main/MainContext";
import { ProfileContextProvider } from "./contexts/main/profile/ProfileContext";
import { SchemaCertificateDetailContextProvider } from "./contexts/main/schema_certificate/detail/SchemaCertificateDetailContext";
import { SchemaRegistrantContextProvider } from "./contexts/main/schema_certificate/registrant/SchemaRegistrantContext";
import { SchemaCertificateContextProvider } from "./contexts/main/schema_certificate/SchemaCertificateContext";
import { BlankSuccessPage, LoginPage, MainPage, ProfilePage, SchemaCertificatePage, SchemaDetailPage, SchemaRegistrantBoardPage, SchemaRegistrantDetailBoardPage, SchemaRegistrantPage } from "./pages";

function App() {
  return (
    <Routes>
      <Route path="/" element={
        <MainContextProvider>
          <MainPage />
        </MainContextProvider>
      } />
      <Route path="/masuk" element={
        <LoginContextProvider>
          <LoginPage />
        </LoginContextProvider>
      } />
      <Route path="/profile/:id" element={
        <ProfileContextProvider>
          <ProfilePage />
        </ProfileContextProvider>
      } />
      <Route path="/skema" element={
        <SchemaCertificateContextProvider>
          <SchemaCertificatePage />
        </SchemaCertificateContextProvider>
      } />
      <Route path="/skema/detail/:id" element={
        <SchemaCertificateDetailContextProvider>
          <SchemaDetailPage />
        </SchemaCertificateDetailContextProvider>
      } />
      <Route path="/skema/registrant/:id" element={
        <SchemaRegistrantContextProvider>
          <SchemaRegistrantPage />
        </SchemaRegistrantContextProvider>
      } />
      {/* Board page */}
      <Route path="/board/schema/registrant" element={
        <ProtectedAuth>
          <SchemaRegistrantBoardContextProvider>
            <SchemaRegistrantBoardPage />
          </SchemaRegistrantBoardContextProvider>
        </ProtectedAuth>
      } />
      <Route path="/board/schema/registrant/detail/:id" element={
        <ProtectedAuth>
          <SchemaRegistrantDetailBoardContextProvider>
            <SchemaRegistrantDetailBoardPage />
          </SchemaRegistrantDetailBoardContextProvider>
        </ProtectedAuth>
      } />
      <Route path="/blank/success" element={
        <BlankSuccessPage />
      } />
    </Routes>
  );
}

export default App;
