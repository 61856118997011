import axios from "axios";
import { API_LOGIN } from "../../configs/api";

export const authLoginRepo = async ({ body = {} }) => {
    try {
        const response = await axios.post(API_LOGIN, body);
        return response;
    } catch (error) {
        return error.response;
    }
}