import axios from "axios";
import { API_SCHEMA_ASSESMENT } from "../../configs/api";

export const showSchemaAssesmentRepo = async ({ schema_id = null, perpage = 10 }) => {
    try {
        const response = await axios.get(`${API_SCHEMA_ASSESMENT}?filter[schema_id]=${schema_id}`);
        return response.data.data.schema_assesment;
    } catch (error) {
        return false;
    }
}