import { createContext, useContext, useEffect, useState, } from "react";
import { useNavigate } from "react-router-dom";
import { showSchemaRegistrantRepo } from "../../../../repos/schema/SchemaRegistrantRepo";

const SchemaRegistrantBoardContext = createContext();

export const SchemaRegistrantBoardContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const [registrant, setRegistrant] = useState({});

    const getRegistrant = async ({ page }) => {
        await showSchemaRegistrantRepo({ page: page }).then((res) => {
            setRegistrant(res);
        });
    }

    const onGetRegistrant = ({ page }) => {
        getRegistrant({ page: page });
    }

    useEffect(() => {
        getRegistrant({});
    }, []);

    return (
        <SchemaRegistrantBoardContext.Provider value={{ navigation, registrant, onGetRegistrant }}>
            {children}
        </SchemaRegistrantBoardContext.Provider>
    );
}

export const UseSchemaRegistrantBoardContext = () => {
    return useContext(SchemaRegistrantBoardContext);
}