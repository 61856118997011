import axios from "axios";
import { API_SCHEMA, API_SCHEMA_CREATE, API_SCHEMA_DETAIL } from "../../configs/api";

export const showSchemaRepo = async ({ perpage = 20 }) => {
    try {
        const response = await axios.get(`${API_SCHEMA}?perpage=${perpage}`);
        return response.data.data.schema;
    } catch (error) {
        return false;
    }
}

export const showSchemaDetailRepo = async ({ schema_id }) => {
    try {
        const response = await axios.get(`${API_SCHEMA_DETAIL}?id=${schema_id}`);
        return response.data.data.schema;
    } catch (error) {
        return false;
    }
}

export const createSchemaRepo = async ({ body = {} }) => {
    try {
        const response = await axios.post(`${API_SCHEMA_CREATE}`, {
            body: body,
        });
        return response;
    } catch (error) {
        return false;
    }
}