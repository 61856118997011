import { IcChevronLeft, logLogo } from "../../../../assets";

const Sidebar = (props) => {
    return (
        <div className="absolute top-0 bottom-0 right-0 left-0 flex justify-start">
            <div className="bg-slate-900 bg-opacity-20 absolute top-0 left-0 bottom-0 right-0 grow z-20" onClick={() => props.onClickOutside && props.onClickOutside()} />
            <div className="bg-white w-[15rem] h-[100vh] z-30">
                <div className="p-2 flex gap-2 items-center">
                    <img src={logLogo} alt="logolsp" className="w-[2.5rem] h-[2.5rem]" />
                    <span className="text-lg">LSP TD</span>
                </div>
                <hr />
                <ul className="mt-2">
                    {props?.menus?.map((item, index) => {
                        return (
                            <li key={index} className="py-2 px-3" onClick={() => item.on_click && item.on_click()}>
                                <div className="flex justify-between items-center">
                                    <div className="flex gap-3">
                                        <span>{item.title}</span>
                                    </div>
                                    {item.child && (
                                        <span>
                                            <IcChevronLeft width="14" height="14" />
                                        </span>
                                    )}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;