import { useNavigate } from "react-router-dom";

const Sidebar = () => {
    const navigation = useNavigate();

    return (
        <aside className="relative w-[13rem] w-min-[13rem] w-max-[13rem] border-e bg-white print-no-print">
            <div className="px-2 py-3 mb-3 border-y cursor-pointer">
                <div className="flex gap-3 items-center justify-center text-sm">
                    <span className="font-medium"></span>
                </div>
            </div>
            <div className="px-4 py-3 cursor-pointer hover:bg-slate-100 flex justify-between items-center" onClick={() => navigation('/board/schema/registrant')}>
                <div className="flex gap-3 items-center text-sm">
                    <span className="font-medium">Pendaftar</span>
                </div>
            </div>
        </aside>
    );
}

export default Sidebar;