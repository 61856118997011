import { useEffect, useRef, useState } from "react";

const ArrowCarousel = (props) => {
    const refCarousel = useRef();
    const [controller, setController] = useState({ prev: false, next: true });

    useEffect(() => {
        onHidenOrShowControl();
        window.addEventListener('resize', () => {
            onHidenOrShowControl();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onHidenOrShowControl = () => {
        refCarousel.current?.clientWidth >= (refCarousel.current?.scrollWidth) ? setController({ ...controller, next: false }) : setController({ ...controller, next: true });
    }

    const getData = () => {
        let control = controller;
        let width = (refCarousel.current.clientWidth - 150);
        let data = { control: control, width: width };
        return data;
    }

    const onPrev = () => {
        let { control, width } = getData();
        refCarousel.current.scrollLeft = refCarousel.current.scrollLeft - width;
        refCarousel.current.scrollLeft - width <= 0 && (control.prev = false);
        !control.next && (control.next = true);
        setController({ ...control });
    }

    const onNext = () => {
        let { control, width } = getData();
        refCarousel.current.scrollLeft = refCarousel.current.scrollLeft + width;
        (refCarousel.current.offsetWidth + refCarousel.current.scrollLeft) >= (refCarousel.current.scrollWidth - width) && (control.next = false);
        !control.prev && (control.prev = true);
        setController({ ...control });
    }

    const renderArrowDefault = () => {
        return (
            <div className="flex items-center relative">
                <div className={`absolute -left-3 bg-white border p-1 rounded-full cursor-pointer shadow-sm ${!controller.prev && 'hidden'}`} onClick={() => { onPrev() }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M15 6l-6 6l6 6"></path>
                    </svg>
                </div>
                <div className="flex gap-3 overflow-auto scrollbar-hidden scroll-smooth" ref={refCarousel}>
                    {props.children}
                </div>
                <div className={`absolute -right-3 bg-white border p-1 rounded-full cursor-pointer shadow-sm ${!controller.next && 'hidden'}`} onClick={() => { onNext() }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 6l6 6l-6 6"></path>
                    </svg>
                </div>
            </div>
        );
    }

    const renderArrowBottom = () => {
        return (
            <div className="relative">
                <div className="flex gap-3 overflow-auto scrollbar-hidden scroll-smooth" ref={refCarousel}>
                    {props.children}
                </div>
                <div className="flex justify-end gap-3 py-3">
                    <div className={`bg-white border p-1 rounded-full ml-3 cursor-pointer`} onClick={() => { onPrev() }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M15 6l-6 6l6 6"></path>
                        </svg>
                    </div>
                    <div className={`bg-white border p-1 rounded-full mr-3 cursor-pointer`} onClick={() => { onNext() }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 6l6 6l-6 6"></path>
                        </svg>
                    </div>
                </div>
            </div>
        );
    }

    const chooseLayout = () => {
        let layout;
        switch (props.layoutType) {
            case 0:
                layout = renderArrowDefault();
                break
            case 1:
                layout = renderArrowBottom();
                break;
            default:
                layout = renderArrowDefault();
                break;
        }

        return layout;
    }

    return chooseLayout();
}

export default ArrowCarousel;