import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { banBanner1, banBanner1_1000, banBanner1_500, banBanner2, banBanner2_1000, banBanner2_500, banBanner3, banBanner3_1000, banBanner3_500, banBanner4, banBanner4_1000, banBanner4_500, banBanner5, banBanner5_1000, banBanner5_500, IcCertif, IcCheck, IcGroup } from "../../assets";
import { imImage1, imImage10, imImage11, imImage12, imImage13, imImage14, imImage15, imImage16, imImage17, imImage18, imImage19, imImage2, imImage20, imImage21, imImage22, imImage23, imImage24, imImage25, imImage26, imImage27, imImage28, imImage29, imImage3, imImage30, imImage31, imImage32, imImage33, imImage34, imImage35, imImage36, imImage4, imImage5, imImage6, imImage7, imImage8, imImage9 } from "../../assets/image";
import { vidVideo1, vidVideo2, vidVideo3, vidVideo4, vidVideo5, vidVideo6, vidVideo7, vidVideo8 } from "../../assets/video";
import { showSchemaRepo } from "../../repos";

const MainContext = createContext();

export const MainContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const [documentation, setDocumentation] = useState([]);
    const banner = [
        {
            thumbnail: window.innerWidth >= 1024 ? banBanner1 : window.innerWidth >= 768 ? banBanner1_1000 : banBanner1_500,
        },
        {
            thumbnail: window.innerWidth >= 1024 ? banBanner2 : window.innerWidth >= 768 ? banBanner2_1000 : banBanner2_500,
        },
        {
            thumbnail: window.innerWidth >= 1024 ? banBanner3 : window.innerWidth >= 768 ? banBanner3_1000 : banBanner3_500,
        },
        {
            thumbnail: window.innerWidth >= 1024 ? banBanner4 : window.innerWidth >= 768 ? banBanner4_1000 : banBanner4_500,
        },
        {
            thumbnail: window.innerWidth >= 1024 ? banBanner5 : window.innerWidth >= 768 ? banBanner5_1000 : banBanner5_500,
        },
    ];
    const feature = [
        {
            thumbnail: <IcCertif width="80" height="80" className="text-cyan-600" />,
            title: '6 Skema Sertifikasi',
            description: 'Skema / Profesi / Jabatan / Pekerjaan di bidang-bidang strategis sektor Teknologi Informasi dan Komunikasi.',
            link: {
                title: 'Jadwal Sertifikasi',
                on_click: () => { },
            },
        },
        {
            thumbnail: <IcCheck width="80" height="80" className="text-cyan-600" />,
            title: '300++ Link DUDI',
            description: 'Perusahaan mitra LSP yang siap untuk menerima profesional bidang IT yang telah tersertifikasi, kompeten dan sesuai bidang keahliannya.',
            link: {
                title: 'Lowongan Pekerjaan',
                on_click: () => { },
            },
        },
        {
            thumbnail: <IcGroup width="80" height="80" className="text-cyan-600" />,
            title: '1000++ SDM Tersertifikasi',
            description: 'Daftar tenaga kerja profesional yang telah tersertifikasi oleh LSP Teknologi Digital. Siap untuk menjawab kebutuhan industri.',
            link: {
                title: 'Mencari Talenta',
                on_click: () => { },
            },
        }
    ];
    const schema_category = [
        {
            title: 'Digital Marketing & Office',
        },
        {
            title: 'Sofware Development',
        },
        {
            title: 'Data Science',
        },
        {
            title: 'Sosial Media',
        },
        {
            title: 'Lihat Semua',
        },
    ];
    const [schema, setSchema] = useState({});
    const article = [
        {
            thumbnail: '',
            title: 'Sertifikat Keahlian Komputer',
            comment: 20,
            view: 100,
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: '',
            title: 'Sertifikat Keahlian Komputer',
            comment: 20,
            view: 100,
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: '',
            title: 'Sertifikat Keahlian Komputer',
            comment: 20,
            view: 100,
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: '',
            title: 'Sertifikat Keahlian Komputer',
            comment: 20,
            view: 100,
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: '',
            title: 'Sertifikat Keahlian Komputer',
            comment: 20,
            view: 100,
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: '',
            title: 'Sertifikat Keahlian Komputer',
            comment: 20,
            view: 100,
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
    ];
    const join = [
        {
            title: 'Asesor Kompetensi (Askom)',
            description: 'Bergabunglah bersama LSP untuk menjadi profesional yang telah memiliki lisensi atau sertifikat kompetensi dari Badan Nasional Sertifikasi Profesi (BNSP) sehingga berwenang melakukan asesmen terhadap para asesi.',
            link: {
                title: 'Menjadi Askom',
                on_click: () => { },
            },
        },
        {
            title: 'Asesor Kompetensi (Askom)',
            description: 'Bergabunglah bersama LSP untuk menjadi profesional yang telah memiliki lisensi atau sertifikat kompetensi dari Badan Nasional Sertifikasi Profesi (BNSP) sehingga berwenang melakukan asesmen terhadap para asesi.',
            link: {
                title: 'Menjadi Askom',
                on_click: () => { },
            },
        },
        {
            title: 'Asesor Kompetensi (Askom)',
            description: 'Bergabunglah bersama LSP untuk menjadi profesional yang telah memiliki lisensi atau sertifikat kompetensi dari Badan Nasional Sertifikasi Profesi (BNSP) sehingga berwenang melakukan asesmen terhadap para asesi.',
            link: {
                title: 'Menjadi Askom',
                on_click: () => { },
            },
        },
        {
            title: 'Asesor Kompetensi (Askom)',
            description: 'Bergabunglah bersama LSP untuk menjadi profesional yang telah memiliki lisensi atau sertifikat kompetensi dari Badan Nasional Sertifikasi Profesi (BNSP) sehingga berwenang melakukan asesmen terhadap para asesi.',
            link: {
                title: 'Menjadi Askom',
                on_click: () => { },
            },
        }
    ];
    const testimony = [
        {
            name: 'Krity Bilal',
            field: 'Google Inc',
            comment: 'Sertifikasi Digital Marketing di LSP Teknologi Digital membuat anda memiliki perbedaan dengan digital marketer lainnya. Kompetensi anda di buktikan langsung oleh para Asesor yang sudah expert di bidangnya',
        },
        {
            name: 'Krity Bilal',
            field: 'Google Inc',
            comment: 'Sertifikasi Digital Marketing di LSP Teknologi Digital membuat anda memiliki perbedaan dengan digital marketer lainnya. Kompetensi anda di buktikan langsung oleh para Asesor yang sudah expert di bidangnya',
        },
        {
            name: 'Krity Bilal',
            field: 'Google Inc',
            comment: 'Sertifikasi Digital Marketing di LSP Teknologi Digital membuat anda memiliki perbedaan dengan digital marketer lainnya. Kompetensi anda di buktikan langsung oleh para Asesor yang sudah expert di bidangnya',
        },
        {
            name: 'Krity Bilal',
            field: 'Google Inc',
            comment: 'Sertifikasi Digital Marketing di LSP Teknologi Digital membuat anda memiliki perbedaan dengan digital marketer lainnya. Kompetensi anda di buktikan langsung oleh para Asesor yang sudah expert di bidangnya',
        },
    ];
    const [documentationCategory, setDocumentationCategory] = useState([
        {
            title: 'Sertifikasi',
            key: 'sertifikasi',
            is_active: true,
        },
        {
            title: 'Kegiatan',
            key: 'kegiatan',
            is_active: false,
        },
        {
            title: 'Workshop',
            key: 'workshop',
            is_active: false,
        },
        {
            title: 'Video',
            key: 'video',
            is_active: false,
        }
    ]);
    const documentationData = [
        {
            thumbnail: imImage1,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage2,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage3,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage4,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage5,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage6,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage7,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage8,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage9,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage10,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage11,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage12,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage13,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage14,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage15,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage16,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage17,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage18,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage19,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage20,
            title: 'Sertifikat Keahlian Komputer',
            category: 'kegiatan',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage21,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage22,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage23,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage24,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage25,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage26,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage27,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage28,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage29,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage30,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage31,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage32,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage33,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage34,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage35,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: imImage36,
            title: 'Sertifikat Keahlian Komputer',
            category: 'sertifikasi',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: vidVideo1,
            title: 'Sertifikat Keahlian Komputer',
            category: 'video',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: vidVideo2,
            title: 'Sertifikat Keahlian Komputer',
            category: 'video',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: vidVideo3,
            title: 'Sertifikat Keahlian Komputer',
            category: 'video',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: vidVideo4,
            title: 'Sertifikat Keahlian Komputer',
            category: 'video',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: vidVideo5,
            title: 'Sertifikat Keahlian Komputer',
            category: 'video',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: vidVideo6,
            title: 'Sertifikat Keahlian Komputer',
            category: 'video',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: vidVideo7,
            title: 'Sertifikat Keahlian Komputer',
            category: 'video',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
        {
            thumbnail: vidVideo8,
            title: 'Sertifikat Keahlian Komputer',
            category: 'video',
            created_by: 'Admin',
            created_at: '18 Agustus 2023',
        },
    ];

    const getSchema = async () => {
        await showSchemaRepo({}).then((res) => {
            setSchema(res);
        });
    }

    const onChangeDocumentationCategory = (index) => {
        documentationCategory.forEach((item) => {
            item.is_active = false;
        });

        documentationCategory[index].is_active = true;
        setDocumentationCategory([...documentationCategory]);

        // Setting documentation
        setDataDocumentation(documentationCategory[index].key);
        getSchema();
    }

    const setDataDocumentation = (key) => {
        const data = documentationData.filter((x) => x.category === key);
        setDocumentation([...data]);
    }

    useEffect(() => {
        onChangeDocumentationCategory(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MainContext.Provider value={{ navigation, banner, feature, schema_category, schema, article, join, testimony, documentationCategory, documentation, onChangeDocumentationCategory }}>
            {children}
        </MainContext.Provider>
    );
}

export const UseMainContext = () => {
    return useContext(MainContext);
}