import { createContext, useContext, useEffect, useState, } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoaderCircle from "../../../../components/main/atom/loader/loaderCircle";
import { createSchemaRegistrantRepo, showSchemaDetailRepo } from "../../../../repos";
import { showSchemaAssesmentRepo } from "../../../../repos/schema/SchemaAssesmentRequest";
import { showSchemaUnitRepo } from "../../../../repos/schema/SchemaUnitRequest";
import { getLocalSchemaRegistrant, removeLocalSchemaRegistrant, setLocalSchemaRegistrant } from "../../../../utils/storage/LocalStorage";

const SchemaRegistrantContext = createContext();

export const SchemaRegistrantContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const params = useParams();
    const [element, setElement] = useState(null);
    const [schema, setSchema] = useState({});
    const [isReadDocument, setIsReadDocument] = useState(false);
    const [controller, setController] = useState({
        nationality: 'Indonesia',
    });
    const [errors, setErrors] = useState({});
    const [currFormTab, setCurrFormTab] = useState({});
    const [formTab, setFormTab] = useState([
        {
            index: 0,
            title: 'Profil Peserta',
            is_active: true,
        },
        {
            index: 1,
            title: 'Dokumen Fortofolio',
            is_active: false,
        },
        {
            index: 2,
            title: 'Assesment Mandiri',
            is_active: false,
        },
    ]);
    const [unit, setUnit] = useState([]);
    const [assesment, setAssesment] = useState([]);

    const getSchemaDetail = async () => {
        await showSchemaDetailRepo({ schema_id: params.id }).then((res) => {
            setSchema(res);
        });
    }

    const getSchemaUnit = async () => {
        await showSchemaUnitRepo({ schema_id: params.id }).then((res) => {
            setUnit(res);
        });
    }

    const onSetIsReadDocument = (value) => {
        setIsReadDocument(value);
        controller.document = value ? { is_in_place: { title: 'Ditempat' } } : {}; //set data to variable
        onSetController('document', value ? { is_in_place: { title: 'Ditempat' } } : {}); //set data to state variable
        onClearProof();
        settingChooseProof();
    }

    const onClearProof = () => {
        // Set empty proof assesment
        assesment.forEach((item, index) => {
            delete assesment[index].proof;
        });
    }

    const getSchemaAssesment = async () => {
        if (assesment.length === 0) {
            await showSchemaAssesmentRepo({ schema_id: params.id }).then((res) => {
                var data = [];
                res?.forEach((item) => {
                    data.push({ ...item, k: false, bk: false });
                });

                setAssesment(data);
                settingChooseProof();
            });
        } else {
            settingChooseProof();
        }
    }

    const onSetController = (field, value) => {
        setController({ ...controller, [field]: value });
        setLocalSchemaRegistrant({ schema_id: params.id, controller: { ...controller, [field]: value }, isReadDocument: isReadDocument, assesment: assesment });
    }

    const onSetAssesment = (field, index, value) => {
        var data = [...assesment];
        data[index] = { ...data[index], [field]: value };
        setAssesment(data);
        setLocalSchemaRegistrant({ schema_id: params.id, controller: { ...controller }, isReadDocument: isReadDocument, assesment: data });
    }

    const onSetAssesmentRadio = (field, field2, index) => {
        var data = [...assesment];
        data[index] = { ...data[index], [field]: true, [field2]: false };

        data.forEach((item) => {
            if (item.is_heading === 1) {
                item[field] = true;
                item[field2] = false;
            }
        });

        setAssesment(data);
        setLocalSchemaRegistrant({ schema_id: params.id, controller: { ...controller }, isReadDocument: isReadDocument, assesment: data });
    }

    const onSetAssesmentRadioAll = (field, field2, value) => {
        var data = [...assesment];

        data.forEach((item) => {
            item[field] = value;
            item[field2] = false;
        });

        setAssesment(data);
        setLocalSchemaRegistrant({ schema_id: params.id, controller: { ...controller }, isReadDocument: isReadDocument, assesment: data });
    }

    const scroll = () => {
        const container = document.getElementsByClassName('content-parent')[0];
        if (container) {
            container.scrollTo(0, 0);
        }
    }

    const onFormTabSwitch = (indexItem) => {
        scroll();
        formTab.forEach((item, index) => {
            formTab[index].is_active = false;
        });

        formTab[indexItem].is_active = true;
        setFormTab([...formTab]);
        setCurrFormTab({ ...formTab[indexItem] });
        formTab[indexItem].index === 1 && getSchemaUnit();
        formTab[indexItem].index === 2 && getSchemaAssesment();
    }

    const settingChooseProof = () => {
        var data = controller.document ?? {};
        var dataArray = Object.keys(data);

        var dataSet = [];
        dataArray.forEach((item) => {
            dataSet.push({ ...data[item], key: item });
        });

        onSetController('document_array', dataSet);
    }

    const onNext = () => {
        currFormTab.index < (formTab.length - 1) && onFormTabSwitch(currFormTab.index + 1);
    }

    const onPrev = () => {
        currFormTab.index > 0 && onFormTabSwitch(currFormTab.index - 1);
    }

    const onSave = async () => {
        setElement(<LoaderCircle />);
        const dataBatch = { ...controller };
        dataBatch.schema_id = params.id;
        dataBatch.step = currFormTab.index;
        dataBatch.assesment = JSON.stringify(assesment);
        dataBatch.gender = controller.gender?.key;
        setErrors({});
        await createSchemaRegistrantRepo({ body: dataBatch }).then((res) => {
            setElement(null);
            res.status === 400 && setErrors(res.data.data);
            if (res.status === 200 && dataBatch.step < (formTab.length - 1)) onNext();
            if (res.status === 200 && dataBatch.step >= (formTab.length - 1)) {
                removeLocalSchemaRegistrant();
                setErrors({});
                setController({});
                navigation('/blank/success');
            }
        });
    }

    useEffect(() => {
        onFormTabSwitch(0);
        getSchemaDetail();
        if (getLocalSchemaRegistrant()?.schema_id === params.id) {
            setController(getLocalSchemaRegistrant()?.controller ?? controller);
            setIsReadDocument(getLocalSchemaRegistrant()?.isReadDocument ?? isReadDocument);
            setAssesment(getLocalSchemaRegistrant()?.assesment ?? assesment);
        } else {
            removeLocalSchemaRegistrant();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SchemaRegistrantContext.Provider value={{ navigation, element, schema, controller, errors, formTab, currFormTab, unit, isReadDocument, assesment, onSetIsReadDocument, setFormTab, setUnit, onSetAssesment, onSetAssesmentRadio, onSetAssesmentRadioAll, onSetController, onPrev, onNext, onSave }}>
            {children}
        </SchemaRegistrantContext.Provider>
    );
}

export const UseSchemaRegistrantContext = () => {
    return useContext(SchemaRegistrantContext);
}