import { logLogo } from "../../assets";
import { ArrowCarousel, Button, Content, SimpleCarousel } from "../../components/main";
import { UseMainContext } from "../../contexts/main/MainContext";
import { elipseText } from "../../utils/text/elipseText";

const MainPage = () => {
    const { navigation, banner, feature, schema_category, schema, article, join, testimony, documentationCategory, documentation, onChangeDocumentationCategory } = UseMainContext();

    return (
        <Content>
            {/* Jumbotron */}
            <SimpleCarousel>
                {banner.map((item, index) => {
                    return (
                        <div key={index} className="bg-slate-200 h-[32vh] md:h-[60vh] w-full min-w-full max-w-full flex justify-center items-center">
                            <img src={item.thumbnail} alt="BannerLSP" className="w-full h-full" />
                        </div>
                    );
                })}
            </SimpleCarousel>
            {/* Content */}
            <div className="flex justify-center">
                <div className="w-full max-w-[1140px] px-3">
                    {/* Filter */}
                    <div className="flex flex-wrap md:flex-nowrap -mt-10">
                        <div className="shadow-all bg-white rounded-t md:rounded-l p-4 grow">
                            <div className="flex flex-wrap md:flex-nowrap gap-5">
                                <div className="flex gap-3 items-center grow">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M22 9l-10 -4l-10 4l10 4l10 -4v6" /><path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4" /></svg>
                                    </div>
                                    <div className="grow">
                                        <small className="font-semibold">Skema</small><br />
                                        <div className="flex justify-between">
                                            <span>Pilih Skema Sertifikasi</span>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-3 items-center grow">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" /><path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" /></svg>
                                    </div>
                                    <div className="grow">
                                        <small className="font-semibold">Pilih Provinsi</small><br />
                                        <div className="flex justify-between">
                                            <span>Di Provinsi Manakah Anda</span>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-3 items-center grow">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4" /><path d="M18 14v4h4" /><path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M15 3v4" /><path d="M7 3v4" /><path d="M3 11h16" /></svg>
                                    </div>
                                    <div className="grow">
                                        <small className="font-semibold">Jadwal</small><br />
                                        <div className="flex justify-between">
                                            <span>17 November 2023</span>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-cyan-600 flex items-center text-white rounded-b md:rounded-r px-4 w-full justify-center py-2 md:w-auto md:justify-start md:py-0">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>
                        </div>
                    </div>
                    <div className="text-center mt-11 mb-7">
                        <h1 className="text-4xl">Lembaga Sertifikasi Profesi</h1>
                    </div>
                    <div className="text-center leading-4">
                        <span className="text-xl font-semibold">Mengapa Kami ?</span>
                        <p className="mt-3 text-lg leading-6">
                            Karena komitmen kami untuk meningkatkan kebertrimaan Sertifikat Kompetensi <br />
                            oleh industri baik di tingkat nasional maupun internasional.
                        </p>
                    </div>
                    {/* Feature */}
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 justify-between mt-10">
                        {feature.map((item, index) => {
                            return (
                                <div key={index} className="grow text-center">
                                    <div className="flex justify-center py-4">{item.thumbnail}</div>
                                    <h2 className="text-lg font-semibold">{item.title}</h2>
                                    <div className="mt-2 mb-4">
                                        <p>{item.description}</p>
                                    </div>
                                    <div className="cursor-pointer" onClick={() => item?.link?.on_click && item.link.on_click()}>
                                        <span className="font-semibold text-cyan-600 hover:text-cyan-700">{item.link.title}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {/* Daftar Skema */}
                    <div className="text-center leading-4 mt-10">
                        <span className="text-xl font-semibold">Daftar Skema Sertifikasi</span>
                        <div className="mt-5">
                            <ArrowCarousel>
                                {schema_category.map((item, index) => {
                                    return (
                                        <Button key={index} className="border-b border-white hover:border-b hover:border-cyan-600 hover:text-cyan-600 text-slate-500 font-medium whitespace-pre">{item.title}</Button>
                                    );
                                })}
                            </ArrowCarousel>
                        </div>
                        <div className="mt-5">
                            <ArrowCarousel>
                                {schema?.data?.map((item, index) => {
                                    return (
                                        <div key={index} className="border rounded p-3 text-start flex flex-col">
                                            <div className="bg-slate-200 rounded h-[12rem] h-max-[12rem] w-min-[15rem] w-[15rem] w-max-[15rem] overflow-hidden p-12">
                                                {<img src={logLogo} alt={item.title} className="w-full h-full object-contain" />}
                                            </div>
                                            <h2 className="text-base font-medium mt-3">{elipseText(item.title, 48)}</h2>
                                            <p className="font-light mt-2 leading-5">{item.description}</p>
                                            <div className="grow" />
                                            <div className="mt-5">
                                                <Button className="bg-cyan-600 text-white flex justify-center" onClick={() => navigation(`/skema/detail/${item.id}`)} >Lihat Skema</Button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </ArrowCarousel>
                        </div>
                    </div>
                    {/* Artikel */}
                    <div className="text-center leading-4 mt-16">
                        <span className="text-xl font-semibold">Artikel</span>
                        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 justify-center">
                            {article.map((item, index) => {
                                return (
                                    <div key={index} className="relative flex flex-col justify-end h-[13.07rem] h-max-[13.07rem]">
                                        <div className="w-full bg-slate-900 bg-opacity-50 z-10 text-white text-start py-3 px-3 rounded-b cursor-pointer">
                                            <div className="opacity-100">
                                                <span className="text-base font-medium">{item.title}</span>
                                                <div className="flex justify-between items-center">
                                                    <small className="font-medium">{item.created_by} - {item.created_at}</small>
                                                    <div className="flex gap-2 items-center">
                                                        <div className="flex gap-1 items-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 20l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c3.255 2.777 3.695 7.266 1.029 10.501c-2.666 3.235 -7.615 4.215 -11.574 2.293l-4.7 1" /></svg>
                                                            <small>{item.comment}</small>
                                                        </div>
                                                        <div className="flex gap-1 items-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                                                            <small>{item.view}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute bg-slate-200 h-full w-full rounded"></div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="flex justify-center mt-7">
                            <Button className="border">Tampilkan Lebih Banyak</Button>
                        </div>
                    </div>
                    {/* Join */}
                    <div className="text-center leading-4 mt-16">
                        <span className="text-xl font-semibold">Bergabunglah dengan Lembaga LSP</span>
                        <div className="mt-5 flex flex-col gap-4">
                            {join.map((item, index) => {
                                return (
                                    <div key={index} className="py-4 px-4 rounded border text-start">
                                        <div className="flex justify-between gap-3 items-center flex-wrap md:flex-nowrap">
                                            <div>
                                                <h5 className="text-base fonr-medium">{item.title}</h5>
                                                <p className="leading-6 font-light mt-1">{item.description}</p>
                                                <div className="mt-1">
                                                    <small className="text-cyan-600 cursor-pointer">Lainnya...</small>
                                                </div>
                                            </div>
                                            <div>
                                                <Button className="bg-cyan-600 text-white whitespace-pre" onClick={() => item?.link?.on_click && item?.link?.on_click()}>{item.link.title}</Button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {/* Testimoni */}
                    <div className="text-center leading-4 mt-16">
                        <span className="text-xl font-semibold">Testimoni</span>
                        <div className="mt-5">
                            <ArrowCarousel>
                                {testimony.map((item, index) => {
                                    return (
                                        <div key={index} className="px-2 rounded border py-4">
                                            <div className="flex justify-center w-min-[20rem] w-[20rem] w-max-[20rem]">
                                                <div className="bg-slate-200 rounded-full w-20 h-20"></div>
                                            </div>
                                            <div className="mt-4">
                                                <span>{item.name}</span><br />
                                                <small>{item.field}</small>
                                                <p className="mt-3 font-light leading-5 text-[0.85rem]">{item.comment}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </ArrowCarousel>
                        </div>
                    </div>
                    {/* Dokumentasi */}
                    <div className="text-center leading-4 mt-16">
                        <span className="text-xl font-semibold">Dokumentasi LSP</span>
                        <div className="flex gap-0 md:gap-2 mt-5 justify-center">
                            {documentationCategory.map((item, index) => {
                                return (
                                    <Button key={index} className={`border-b border-white ${item.is_active ? 'border-cyan-600 text-cyan-600' : 'text-slate-500'} hover:border-b hover:border-cyan-600 hover:text-cyan-600 font-medium text-xs md:text-[15px]`} onClick={() => onChangeDocumentationCategory(index)}>{item.title}</Button>
                                );
                            })}
                        </div>
                        <div className="mt-5 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3 justify-center">
                            {documentation.map((item, index) => {
                                return (
                                    <div key={index} className="flex flex-col gap-1 grow">
                                        <div className="grow h-[9.7rem] h-max-[9.7rem] bg-slate-200 rounded overflow-hidden">
                                            {item.category === 'video' ? (
                                                <video width="750" height="500" controls className="rounded">
                                                    <source src={item.thumbnail} type="video/mp4" />
                                                </video>
                                            ) : (<img src={item.thumbnail} alt="portofolio" className="w-full h-full object-fill" />)}
                                        </div>
                                        <div className="text-start mt-1">
                                            {/* <span className="font-medium">{item.title}</span><br /> */}
                                            {/* <small>{item.created_by} - {item.created_at}</small> */}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="flex justify-center mt-7">
                            <Button className="border">Tampilkan Lebih Banyak</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default MainPage;