export const getLocalToken = () => {
    return JSON.parse(localStorage.getItem('lsp.user'));
}

export const setLocalUser = (value) => {
    localStorage.setItem('lsp.user', JSON.stringify(value));
}

export const getLocalUser = () => {
    return JSON.parse(localStorage.getItem('lsp.user'));
}

export const setLocalSchemaRegistrant = (value) => {
    localStorage.setItem('lsp.schema_registrant', JSON.stringify(value));
}

export const getLocalSchemaRegistrant = () => {
    return JSON.parse(localStorage.getItem('lsp.schema_registrant'));
}

export const removeLocalSchemaRegistrant = () => {
    return localStorage.removeItem('lsp.schema_registrant');
}

export const clearLocal = () => {
    localStorage.clear();
}