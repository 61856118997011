import { Button, Content, InputPassword, InputText } from "../../../components/main";
import { UseLoginContext } from "../../../contexts/auth/login/LoginContext";

const LoginPage = () => {
    const { controller, errors, onSetController, onLogin } = UseLoginContext();

    return (
        <Content>
            <div className="flex justify-center mt-4">
                <div className="w-full max-w-[1140px] px-3 flex justify-center gap-5">
                    <div className="border rounded px-6 pb-10 pt-7 min-w-[320px] w-[320px] max-w-[320px]">
                        <div className="text-center">
                            <span className="font-medium text-base">Masuk</span>
                        </div>
                        <div className="mt-7">
                            {errors.message && (<div className="bg-red-800 text-white rounded text-center py-1"><small className="font-medium">{errors.message}</small></div>)}
                        </div>
                        <div className="mt-2">
                            <small className="font-medium">Email</small>
                            <InputText className="mt-1" value={controller.email} error={errors.email} onChange={(value) => onSetController('email', value)} placeholder="..." />
                        </div>
                        <div className="mt-2">
                            <small className="font-medium">Password</small>
                            <InputPassword className="mt-1" value={controller.password} error={errors.password} onChange={(value) => onSetController('password', value)} />
                        </div>
                        <div className="mt-5">
                            <Button className="flex justify-center bg-cyan-600 text-white py-[7px]" onClick={() => onLogin()}>Masuk</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default LoginPage;