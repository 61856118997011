import { createContext, useContext, useEffect, useState, } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { showSchemaDetailRepo } from "../../../../repos";
import { showSchemaUnitRepo } from "../../../../repos/schema/SchemaUnitRequest";

const SchemaCertificateDetailContext = createContext();

export const SchemaCertificateDetailContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const params = useParams();
    const [schema, setSchema] = useState({});
    const [unit, setUnit] = useState([]);

    const getSchemaDetail = async () => {
        await showSchemaDetailRepo({ schema_id: params['id'] }).then((res) => {
            setSchema(res);
        });
    }

    const getSchemaUnit = async () => {
        await showSchemaUnitRepo({ schema_id: params.id }).then((res) => {
            setUnit(res);
        });
    }

    useEffect(() => {
        getSchemaDetail();
        getSchemaUnit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SchemaCertificateDetailContext.Provider value={{ navigation, schema, unit }}>
            {children}
        </SchemaCertificateDetailContext.Provider>
    );
}

export const UseSchemaCertificateDetailContext = () => {
    return useContext(SchemaCertificateDetailContext);
}