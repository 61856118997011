import { Card, Content } from "../../../../components/board";
import { UseSchemaRegistrantBoardContext } from "../../../../contexts/board/schema_certificate/registrant/SchemaRegistrantBoardContext";

const SchemaRegistrantBoardPage = () => {
    const { navigation, registrant, onGetRegistrant } = UseSchemaRegistrantBoardContext();

    const renderNavigation = ({ pages = [], currentPage = 1, onCallback }) => {
        return (
            <div className="flex gap-2">
                {pages?.map((item, index) => {
                    if (index === 0) return <div key={index} className={`px-[0.4rem] py-[0.10rem] border rounded-md border-slate-200 flex justify-center items-center cursor-pointer ${currentPage === 1 && 'bg-slate-200'}`} onClick={() => (onCallback && currentPage > 1) ? onCallback(currentPage - 1) : {}}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 6l-6 6l6 6" /></svg></div>;
                    if (index === (pages.length - 1)) return <div key={index} className={`px-[0.4rem] py-[0.10rem] border rounded-md border-slate-200 flex justify-center items-center cursor-pointer ${currentPage === (pages.length - 2) && 'bg-slate-200'}`} onClick={() => (onCallback && currentPage < (pages.length - 2)) ? onCallback(currentPage + 1) : {}}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg></div>;
                    return <div key={index} className={`px-[0.7rem] py-[0.10rem] border rounded-md flex justify-center items-center cursor-pointer ${(currentPage) === index ? 'border-cyan-600 text-cyan-600' : 'border-slate-200 text-dark'}`} onClick={() => onCallback ? onCallback(index) : {}}><span className="text-[12px]">{item.label}</span></div>;
                })}
            </div>
        );
    }

    return (
        <Content>
            <Card className="px-4 pt-4 pb-10 w-full">
                <div className="w-full overflow-x-auto">
                    <table className="w-full border-collapse">
                        <thead className="bg-slate-50">
                            <tr>
                                <th className="border-b-[1.5px] border-slate-200 pl-5 pr-3 py-2 text-start whitespace-pre">
                                    Nama Lengkap
                                </th>
                                <th className="border-b-[1.5px] border-slate-200 px-3 py-2 text-start whitespace-pre">No. KTP/NIK/Paspor</th>
                                <th className="border-b-[1.5px] border-slate-200 px-3 py-2 text-start whitespace-pre">Tempat / tgl. Lahir</th>
                                <th className="border-b-[1.5px] border-slate-200 px-3 py-2 text-start whitespace-pre">Jenis kelamin</th>
                                <th className="border-b-[1.5px] border-slate-200 px-3 py-2 text-start whitespace-pre">Kebangsaan</th>
                                <th className="border-b-[1.5px] border-slate-200 px-3 py-2 text-start whitespace-pre">Alamat rumah</th>
                                <th className="border-b-[1.5px] border-slate-200 px-3 py-2 text-start whitespace-pre">No. Telepon</th>
                                <th className="border-b-[1.5px] border-slate-200 px-3 py-2 text-start whitespace-pre">E-mail</th>
                                <th className="border-b-[1.5px] border-slate-200 px-3 py-2 text-start whitespace-pre">Kualifikasi Pendidikan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {registrant?.data?.map((item, index) => {
                                return (
                                    <tr key={index} className="hover:bg-slate-100 cursor-pointer" onClick={() => navigation(`/board/schema/registrant/detail/${item.id}`)}>
                                        <td className="border-b-[1.5px] border-slate-200 pl-5 pr-3 py-2">
                                            {item.name}
                                        </td>
                                        <td className="border-b-[1.5px] border-slate-200 px-3 py-2">
                                            {item.no_identity}
                                        </td>
                                        <td className="border-b-[1.5px] border-slate-200 px-3 py-2">
                                            {`${item.place_of_birth} / ${item.date_of_birth}`}
                                        </td>
                                        <td className="border-b-[1.5px] border-slate-200 pl-3 pr-5 py-2">
                                            {item.gender}
                                        </td>
                                        <td className="border-b-[1.5px] border-slate-200 px-3 py-2">
                                            {item.nationality}
                                        </td>
                                        <td className="border-b-[1.5px] border-slate-200 px-3 py-2">
                                            {item.address}
                                        </td>
                                        <td className="border-b-[1.5px] border-slate-200 px-3 py-2">
                                            {item.telp}
                                        </td>
                                        <td className="border-b-[1.5px] border-slate-200 px-3 py-2">
                                            {item.email}
                                        </td>
                                        <td className="border-b-[1.5px] border-slate-200 px-3 py-2">
                                            {item.education}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <hr className="my-3" />
                <div className="mt-3 flex justify-end">
                    {renderNavigation({ pages: registrant?.links ?? [], currentPage: registrant?.current_page, onCallback: ((page) => onGetRegistrant({ page: page })) })}
                </div>
            </Card>
        </Content>
    );
}

export default SchemaRegistrantBoardPage;