import axios from "axios";
import { API_SCHEMA_UNIT } from "../../configs/api";

export const showSchemaUnitRepo = async ({ schema_id = null, perpage = 10 }) => {
    try {
        const response = await axios.get(`${API_SCHEMA_UNIT}?filter[schema_id]=${schema_id}`);
        return response.data.data.schema_unit;
    } catch (error) {
        return false;
    }
}