import { useNavigate } from "react-router-dom";
import { Button, Content } from "../../../../components/main";

const BlankSuccessPage = () => {
    const navigation = useNavigate();

    return (
        <Content>
            <div className="flex justify-center mt-4">
                <div className="w-full max-w-[1140px] px-3 flex justify-center gap-5">
                    <div className="grow border rounded-lg px-4 py-10 text-center">
                        <span className="text-xl">Formulir sudah dikirimkan</span><br />
                        <span>Kirim formulir lain?</span>
                        <div className="mt-10 flex justify-center">
                            <Button className="bg-slate-700 text-white" onClick={() => navigation(-1)}>Kembali Ke Halaman Formulir</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}

export default BlankSuccessPage;