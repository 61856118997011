const InputSlider = (props) => {
    const isActive = props.value;

    const onSetIsActive = () => {
        props.onChange && props.onChange(!isActive);
    }

    return (
        <div className="flex justify-center items-center">
            <div className={`rounded-full w-10 p-1 flex cursor-pointer ${isActive ? 'justify-end bg-slate-700' : 'justify-start border border-slate-700'}`} onClick={() => onSetIsActive()}>
                <div className={`rounded-full w-3 h-3 ${isActive ? 'bg-white' : 'bg-slate-700'}`} />
            </div>
        </div>
    );
}

export default InputSlider;