import { logLogo } from "../../../assets";
import { Button, Content } from "../../../components/main";
import { UseSchemaCertificateContext } from "../../../contexts/main/schema_certificate/SchemaCertificateContext";
import { elipseText } from "../../../utils/text/elipseText";

const SchemaCertificatePage = () => {
    const { navigation, schema } = UseSchemaCertificateContext();

    return (
        <Content>
            <div className="flex justify-center mt-4">
                <div className="w-full max-w-[1140px] px-3 flex gap-5">
                    <div className="min-w-[15rem] w-[15rem] max-w-[15rem] hidden lg:block">
                        <div className="sticky top-24">
                            <span className="text-base font-semibold">Skema Sertifikasi</span>
                            <div className="mt-2">
                                <small>Filter By</small>
                                <div className="border rounded bg-white w-full p-2 mt-2">
                                    <ul>
                                        <li className="px-2 py-1 cursor-pointer hover:bg-slate-100 rounded"><span className="text-sm">Semua Skema</span></li>
                                        <li className="px-2 py-1 cursor-pointer hover:bg-slate-100 rounded"><span className="text-sm">Skema Populer</span></li>
                                        <li className="px-2 py-1 cursor-pointer hover:bg-slate-100 rounded"><span className="text-sm">Skema Terbaru</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grow">
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                            {schema?.data?.map((item, index) => {
                                return (
                                    <div key={index} className="border rounded p-3 text-start flex flex-col">
                                        <div className="bg-slate-200 rounded aspect-square p-12">
                                            {<img src={logLogo} alt={item.title} className="w-full h-full object-fill" />}
                                        </div>
                                        <h2 className="text-base font-medium mt-3">{elipseText(item.title, 32)}</h2>
                                        <p className="font-light mt-2 leading-5">{item.description}</p>
                                        <div className="grow" />
                                        <div className="mt-5">
                                            <Button className="bg-cyan-600 text-white flex justify-center py-[7px]" onClick={() => navigation(`/skema/detail/${item.id}`)}>Lihat Skema</Button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default SchemaCertificatePage;