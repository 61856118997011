import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authLoginRepo } from "../../../repos";
import { setLocalUser } from "../../../utils/storage/LocalStorage";

const LoginContext = createContext();

export const LoginContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const [controller, setController] = useState({});
    const [errors, setErrors] = useState({});

    const onSetController = (field, value) => {
        setController({ ...controller, [field]: value });
    }

    const onLogin = async () => {
        const dataBatch = { ...controller };
        setErrors({});
        await authLoginRepo({ body: dataBatch }).then((res) => {
            res.status === 400 && setErrors(res.data.data);
            res.status === 401 && setErrors({ message: res.data.message });
            res.status === 200 && setLocalUser(res.data.data);
            res.status === 200 && navigation('/board/schema/registrant');
        });
    }

    return (
        <LoginContext.Provider value={{ navigation, controller, errors, onSetController, onLogin }}>
            {children}
        </LoginContext.Provider>
    );
}

export const UseLoginContext = () => {
    return useContext(LoginContext);
}