import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IcChevronDown, IcMenus, logLogo } from "../../../../assets";
import Sidebar from "../sidebar/Sidebar";


const Navbar = () => {
    const navigation = useNavigate();
    const [element, setElement] = useState(false);
    const [menus, setMenus] = useState([
        {
            title: 'Home',
            on_click: () => navigation('/'),
        },
        {
            title: 'Profil',
            child_active: false,
            child: [
                {
                    title: 'Tentang Kami',
                    on_click: () => navigation('/profile/1'),
                },
                {
                    title: 'Visi & Misi',
                    on_click: () => navigation('/profile/2'),
                },
                {
                    title: 'Struktur Organisasi',
                    on_click: () => navigation('/profile/3'),
                },
                {
                    title: 'Stake Holder',
                    on_click: () => navigation('/profile/4'),
                },
                {
                    title: 'Komitmen Ketidak Berpihakan',
                    on_click: () => navigation('/profile/5'),
                },
                {
                    title: 'Rencana Strategis',
                    on_click: () => navigation('/profile/6'),
                },
                {
                    title: 'Program Kerja',
                    on_click: () => navigation('/profile/7'),
                },
                {
                    title: 'Mitra Kerja',
                    on_click: () => navigation('/profile/8'),
                },
            ],
        },
        {
            title: 'Media',
            child_active: false,
            child: [
                {
                    title: 'Instagram',
                    on_click: () => window.open('https://instagram.com/mkbi.official?igshid=OGQ5ZDc2ODk2ZA=='),
                },
                {
                    title: 'Facebook',
                    on_click: () => window.open('https://www.facebook.com/profile.php?id=61550340341592&mibextid=ZbWKwL'),
                },
                {
                    title: 'Twitter',
                    on_click: () => { window.open('https://twitter.com/LspMKBI') },
                },
                {
                    title: 'WhatsApp',
                    on_click: () => { window.open('https://wa.me/6281214615665') },
                }
            ],
        },
        {
            title: 'Informasi',
        },
        {
            title: 'Skema Sertifikasi',
            on_click: () => navigation('/skema'),
        },
    ]);

    const onOver = (index) => {
        menus[index].child_active = true;
        setMenus([...menus]);
    }

    const onLeave = (index) => {
        menus[index].child_active = false;
        setMenus([...menus]);
    }

    return (
        <>
            <nav className="bg-slate-800 text-slate-400 flex justify-center font-semibold p-4">
                <div className="w-full max-w-[1140px] flex justify-between">
                    <div className="flex gap-3">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg>
                        </span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" /><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M16.5 7.5l0 .01" /></svg>
                        </span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M2 8a4 4 0 0 1 4 -4h12a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-8z" /><path d="M10 9l5 3l-5 3z" /></svg>
                        </span>
                        <span className="hidden md:block">
                            BNSP-LSP-1565-ID
                        </span>
                    </div>
                    <div className="flex gap-3">
                        <span className="hover:text-slate-300 cursor-pointer" onClick={() => navigation('/masuk')}>Login</span>
                        <span className="hover:text-slate-300 cursor-pointer">Contact</span>
                    </div>
                </div>
            </nav>
            <nav className="bg-slate-50 flex justify-center p-1 pr-3 sticky -top-1 z-20">
                <div className="w-full max-w-[1140px] flex justify-between items-center">
                    <div className="flex gap-2 items-center">
                        <span>
                            <img src={logLogo} alt="LogoCompanyProfile" className="w-14 md:w-16 lg:w-20 p-2" />
                        </span>
                        <div className="leading-4">
                            <span className="font-semibold">LSP MKBI</span>
                            <ul className="text-sm hidden md:block leading-3">
                                <li><small>Lembaga Sertifikasi Profesi</small></li>
                                <li><small>Manajement Kewirausahaan dan</small></li>
                                <li><small>Bisnis Indonesia</small></li>
                            </ul>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <ul className="flex gap-4">
                            {menus.map((item, index) => {
                                return (
                                    <li key={index} className="font-semibold text-slate-700 uppercase cursor-pointer relative" onClick={() => item.on_click && item.on_click()} onMouseOver={() => onOver(index)} onMouseLeave={() => onLeave(index)}>
                                        <div className="flex gap-2 items-center">
                                            {item.title}
                                            {item.child && (
                                                <span><IcChevronDown width="21" height="21" /></span>
                                            )}
                                        </div>
                                        {item.child && (
                                            <div className={`p-2 absolute top-5 right-0 ${item.child_active ? 'block' : 'hidden'}`}>
                                                <div className="bg-white border rounded p-2 w-max-[17rem]">
                                                    <ul>
                                                        {item.child?.map((itemChild, indexChild) => {
                                                            return (
                                                                <li key={indexChild} className="p-1 hover:bg-slate-100 hover:rounded leading-5" onClick={() => itemChild.on_click && itemChild.on_click()}>
                                                                    <span className="font-normal whitespace-pre">{itemChild.title}</span>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    {/* Button */}
                    <div className="block md:hidden">
                        <span onClick={() => setElement(<Sidebar menus={menus} onClickOutside={() => setElement(false)} />)}>
                            <IcMenus />
                        </span>
                    </div>
                </div>
            </nav>
            {element && element}
        </>
    );
}

export default Navbar;