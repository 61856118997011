const InputFile = (props) => {

    const handleInputChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const fileData = reader.result;
            props.onChange && props.onChange({
                name: file.name,
                file: fileData,
            });
        };
    };

    return (
        <div className="leading-3 w-full">
            <label htmlFor={props.id} className="cursor-pointer">
                <div className="flex">
                    <div className="border border-r-0 border-slate-400 px-3 py-3 rounded-l-md bg-slate-700 text-white text-xs">Choose File</div>
                    <div className="border border-slate-400 px-3 grow py-3 rounded-r-md text-slate-500">{props.value ?? 'Belum ada file yang di upload'}</div>
                </div>
            </label>
            <input id={props.id} name={props.name} type="file" className={`border hidden rounded-md px-3 py-2 w-full focus:outline-none ${props.className}`} placeholder={props.placeholder} onChange={(event) => handleInputChange(event)} />
            {props.error && <small className="text-red-800 pl-1 inline-block">{props.error}</small>}
        </div>
    );
}

export default InputFile;