const REACT_APP_API_URL = `${process.env.REACT_APP_ENV}/api/v1/`;

// Auth
export const API_LOGIN = `${REACT_APP_API_URL}login`;
export const API_REGISTER = `${REACT_APP_API_URL}register`;
// Schema
export const API_SCHEMA = `${REACT_APP_API_URL}schema`;
export const API_SCHEMA_DETAIL = `${REACT_APP_API_URL}schema/detail`;
export const API_SCHEMA_CREATE = `${REACT_APP_API_URL}schema/create`;
// Schema unit
export const API_SCHEMA_UNIT = `${REACT_APP_API_URL}schema/unit`;
// Schema Assesment
export const API_SCHEMA_ASSESMENT = `${REACT_APP_API_URL}schema/assesment`;
// Schema Registrant
export const API_SCHEMA_REGISTRANT = `${REACT_APP_API_URL}schema/registrant`;
export const API_SCHEMA_REGISTRANT_DETAIL = `${REACT_APP_API_URL}schema/registrant/detail`;
export const API_SCHEMA_REGISTRANT_CREATE = `${REACT_APP_API_URL}schema/registrant/create`;