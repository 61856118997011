import { Navigate } from "react-router-dom";
import { getLocalUser } from "../../../utils/storage/LocalStorage";

const ProtectedAuth = ({ children }) => {
    const data = getLocalUser();
    if (!data) {
        return <Navigate to={'/masuk'} />
    }

    return children;
}

export default ProtectedAuth;