import { Button, Content } from "../../../../components/main";
import { UseSchemaCertificateDetailContext } from "../../../../contexts/main/schema_certificate/detail/SchemaCertificateDetailContext";

const SchemaDetailPage = () => {
    const { navigation, schema, unit } = UseSchemaCertificateDetailContext();

    return (
        <Content>
            <div className="flex justify-center mt-4 sm-zoom-50">
                <div className="w-full max-w-[1140px] px-3 flex justify-center gap-5">
                    <div className="p-4 rounded border grow bg-slate-100">
                        <div className="flex justify-center">
                            <span className="font-semibold text-2xl text-slate-700">BIG DATA SCIENTIST</span>
                        </div>
                        <div className="my-4">
                            <table className="border-x w-full">
                                <tbody>
                                    <tr>
                                        <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem]">Judul</td>
                                        <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem] text-center w-[0.5rem]">:</td>
                                        <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem]">{schema.title}</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem]">Nomor</td>
                                        <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem] text-center w-[0.5rem]">:</td>
                                        <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem]">{schema.number}</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem]">Skema Sertifikasi</td>
                                        <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem] text-center w-[0.5rem]">:</td>
                                        <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem]" dangerouslySetInnerHTML={{ __html: schema.certification }} />
                                    </tr>
                                    <tr>
                                        <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem]" rowSpan={schema.purpose_decode?.length ?? 1} valign="top">
                                            Tujuan Assesment
                                        </td>
                                        <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem] text-center w-[0.5rem]">:</td>
                                        <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem]">{schema.purpose_decode?.[0]}</td>
                                    </tr>
                                    {schema.purpose_decode?.map((item, index) => {
                                        return index !== 0 && (
                                            <tr key={index}>
                                                <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem] text-center w-[0.5rem]"></td>
                                                <td className="border border-slate-300 text-slate-700 font-medium p-[0.5rem]">{item}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="flex justify-end">
                            <Button className="bg-cyan-600 text-white text-xs" onClick={() => navigation(`/skema/registrant/${schema.id}`)}>Daftar Uji Kompetensi</Button>
                        </div>
                        <hr className="my-4" />
                        <div>
                            <span className="font-medium text-base">DAFTAR UNIT KOMPETENSI</span>
                            <div className="mt-2">
                                <table className="w-full">
                                    <thead>
                                        <tr>
                                            <th className="border border-slate-300 text-slate-700 p-3 font-semibold">No</th>
                                            <th className="border border-slate-300 text-slate-700 p-3 font-semibold">Kode Unit</th>
                                            <th className="border border-slate-300 text-slate-700 p-3 font-semibold">Judul Unit Kompetensi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {unit.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="border border-slate-300 text-slate-700 p-3 text-center">{(index + 1)}</td>
                                                    <td className="border border-slate-300 text-slate-700 p-3 text-center">{item.code}</td>
                                                    <td className="border border-slate-300 text-slate-700 p-3 font-medium">{item.title}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default SchemaDetailPage;