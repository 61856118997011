import { Content } from "../../../components/main";
import { UseProfileContext } from "../../../contexts/main/profile/ProfileContext";

const ProfilePage = () => {
    const { currentContent } = UseProfileContext();

    return (
        <Content>
            <div className="flex justify-center mt-4">
                <div className="w-full max-w-[1140px] px-3 flex gap-5">
                    <div className="min-w-[15rem] w-[15rem] max-w-[15rem] hidden lg:block">
                        <div className="sticky top-24">
                            <span className="font-semibold">Daftar Isi</span>
                            <ul className="mt-2">
                                {currentContent?.list_of_content?.map((item, index) => {
                                    return (
                                        <li key={index} className="py-[0.10rem] cursor-pointer hover:underline hover:rounded">{item.title}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="grow" dangerouslySetInnerHTML={{ __html: currentContent.content?.content }} />
                </div>
            </div>
        </Content>
    );
}

export default ProfilePage;