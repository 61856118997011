import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { logLogo } from "../../../assets";

const ProfileContext = createContext();

export const ProfileContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const param = useParams();
    const [currentContent, setCurrentContent] = useState({});
    const content = [
        {
            list_of_content: [
                {
                    title: 'Tentang Kami',
                },
                {
                    title: 'Mengapa Memilih Kami?',
                },
                {
                    title: 'Tim Kami',
                },
                {
                    title: 'Bergabunglah Bersama Kami',
                },
            ],
            content: {
                content: `<h1 class="text-3xl font-semibold">Tentang Kami/ About US Lsp MKBI</h1>
                <img src="${logLogo}" width="200" class="my-4" />
                <p class="mt-3 mb-4">Selamat datang di LSP MKBI [Lemabaga Sertifikasi Profesi Manajemen Kewirausahaan dan Bisnis Indonesia], penyedia sertifikasi profesi kewirausahaan dan pemasaran yang berkomitmen untuk mengangkat standar industri dan mendukung perkembangan karir para profesional di bidang ini. Sebagai lembaga yang didedikasikan untuk menyediakan pengakuan resmi terhadap keahlian dan pengetahuan di dunia kewirausahaan dan pemasaran, kami memiliki misi untuk membantu individu mencapai potensi penuh mereka dalam karir yang dinamis ini.</p>
                <h1 class="text-3xl font-semibold mb-2">Mengapa Memilih Kami?</h1>
                <ol class="list-decimal px-3">
                <li><span class="text-slate-700 font-bold">Standar Kualitas Tinggi:</span> Kami berkomitmen untuk menyelenggarakan ujian yang mencerminkan standar kualitas tertinggi dalam industri. Materi ujian kami diperbarui secara berkala untuk mencerminkan perubahan dan perkembangan terbaru.</li>
                <li><span class="text-slate-700 font-bold">Dukungan Pendidikan dan Pelatihan:</span> Selain sertifikasi, kami juga menyediakan dukungan pendidikan dan pelatihan tambahan untuk membantu pemegang sertifikasi tetap relevan dan berkembang di karir mereka.</li>
                <li><span class="text-slate-700 font-bold">Mitra Industri Terpercaya:</span> Kami bekerja sama dengan para profesional dan perusahaan terkemuka di bidang kewirausahaan dan pemasaran untuk memastikan bahwa sertifikasi kami selalu relevan dengan tuntutan pasar kerja.</li>
                <li><span class="text-slate-700 font-bold">Dampak Positif pada Karir:</span> Pemegang sertifikasi kami mendapatkan keunggulan kompetitif di pasar kerja. Kami bangga menyaksikan bagaimana sertifikasi kami memberikan dampak positif pada perkembangan karir para profesional.</li>
                </ol>
                <h1 class="text-3xl font-semibold mb-2 mt-4">Tim Kami</h1>
                <p>Tim kami terdiri dari para ahli di bidang kewirausahaan dan pemasaran yang berkomitmen untuk memberikan layanan terbaik kepada para peserta ujian. Mereka membawa pengalaman praktis dan pemahaman mendalam tentang tren industri saat ini.</p>
                <h1 class="text-3xl font-semibold mb-2 mt-4">Bergabunglah Bersama Kami</h1>
                <p>Apakah Anda seorang calon yang ingin mengukur dan membuktikan keterampilan Anda di dunia kewirausahaan atau pemasaran, atau seorang perusahaan yang mencari pemegang sertifikasi berkualitas tinggi, [Nama Lembaga Sertifikasi] adalah mitra terpercaya Anda. Bergabunglah dengan kami dan jadilah bagian dari komunitas yang berdedikasi untuk mencapai keunggulan di bidang kewirausahaan dan pemasaran.</p>
                </br></br>`,
            },
        },
        {
            list_of_content: [
                {
                    title: 'Visi',
                },
                {
                    title: 'Misi',
                },
            ],
            content: {
                content: `<h1 class="text-3xl font-semibold">Visi & Misi</h1>
                <img src="${logLogo}" width="200" class="my-4" /></br>
                <h2 class="text-2xl font-semibold mt-4">Visi</h2>
                <p>Menjadi lembaga sertifikasi terkemuka yang diakui secara global, menginspirasi keunggulan di bidang kewirausahaan dan pemasaran, serta menciptakan dampak positif dalam pengembangan profesional para praktisi.</p>
                <h2 class="text-2xl font-semibold mt-4">Misi</h2>
                <ol class="list-decimal px-3">
                <li><span class="text-slate-700 font-bold">Mengukur dan Mengakui Kompetensi:</span> Menyediakan ujian sertifikasi yang cermat dan adil untuk mengukur pengetahuan dan keterampilan praktis yang diperlukan untuk sukses di dunia kewirausahaan dan pemasaran.</li>
                <li><span class="text-slate-700 font-bold">Mendukung Pengembangan Profesional:</span> Menyediakan sumber daya pendidikan, pelatihan tambahan, dan wadah kolaborasi untuk membantu pemegang sertifikasi tetap terdepan dalam tren industri dan memajukan karir mereka.</li>
                <li><span class="text-slate-700 font-bold">Menciptakan Dampak Positif:</span> Memberikan dampak positif pada perkembangan karir individu dengan memberikan pengakuan resmi terhadap prestasi mereka, menciptakan kepercayaan diri yang tinggi di dalam dan di luar tempat kerja.</li>
                <li><span class="text-slate-700 font-bold">Bekerja Sama dengan Industri:</span> Mengembangkan kemitraan yang erat dengan perusahaan dan profesional terkemuka di industri kewirausahaan dan pemasaran untuk memastikan bahwa sertifikasi kami selalu mencerminkan kebutuhan aktual pasar kerja.</li>
                <li><span class="text-slate-700 font-bold">Menyebarkan Nilai Etika Bisnis:</span> Mendorong praktik bisnis yang etis dan tanggung jawab sosial dalam komunitas kewirausahaan dan pemasaran, menciptakan pemimpin yang berintegritas dan peduli terhadap dampak sosial.</li>
                <li><span class="text-slate-700 font-bold">Membangun Komunitas yang Kuat:</span> Menggalang komunitas yang aktif dan berdedikasi, memfasilitasi pertukaran ide dan pengalaman antar pemegang sertifikasi, serta menyediakan platform untuk pertumbuhan dan kolaborasi bersama.</li>
                </ol><br/><br/><br/>`,
            },
        },
        {
            list_of_content: [
                {
                    title: 'Unsur Pengarah',
                },
                {
                    title: 'Unsur Pengurus',
                },
            ],
            content: {
                content: `<h1 class="text-3xl font-semibold">Struktur Organisasi</h1>
                <img src="${logLogo}" width="200" class="my-4" />
                <h1 class="text-3xl font-semibold">TUGAS DAN TANGGUNG JAWAB PENGURUS LSP</h1>
                <h1 class="text-3xl font-semibold mt-3">UNSUR PENGARAH</h1>
                <p class="mt-2 font-semibold">Dewan Pengarah dan Komite Ketidakberpihakan</p>
                <ul class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ul>
                <p class="mt-2 font-semibold">Komite Skema</p>
                <ul class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ul>
                <h1 class="text-3xl font-semibold mt-3">UNSUR PENGURUS</h1>
                <p class="mt-2 font-semibold">Direktur Eksekutif (PENGURUS HARIAN)</p>
                <ul class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ul>
                <p class="mt-2 font-semibold">Bendahara</p>
                <ul class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ul>
                <p class="mt-2 font-semibold">Manajer  Mutu</p>
                <ul class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ul>
                <p class="mt-2 font-semibold">Manajer Sertifikasi</p>
                <ul class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ul>
                <p class="mt-2 font-semibold">Manajer Pengelola LSP</p>
                <ul class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ul>`,
            },
        },
        {
            list_of_content: [
                {
                    title: 'Asosiasi Profesi',
                },
                {
                    title: 'Asosiasi Industri',
                },
                {
                    title: 'Kementrian Pembina',
                },
            ],
            content: {
                content: `<h1 class="text-3xl font-semibold">Stakeholder</h1>
                <img src="${logLogo}" width="200" class="my-4" />
                <h2 class="text-2xl font-semibold mt-4">Asosiasi Profesi</h2>
                <p>Asosiasi Profesi adalah Wadah organisasi atau himpunan perorangan, atas dasar kesamaan disiplin keilmuan di bidang Teknologi Informasi dan Teknologi Digital sebagai upaya dalam mengembangkan keahlian serta memperjuangkan aspirasi para anggota.  </p>
                <h2 class="text-2xl font-semibold mt-4">Asosiasi Industri</h2>
                <p>Asosiasi Industri beranggotakan para principle atau vendor, merk nasional, pabrikan, distributor, system integrator, dealer, penyedia konten, pengembang perangkat lunak/VAR, retailer, dan lembaga pendidikan.
                LSP Teknologi digital di dirikan oleh Asosiasi profesi dan Asosiasi Industri, juga didukung oleh Asosiasi Profesi untuk eksistensi LSP di Dunia Usaha dan Dunia Industri. LSP TD didukung oleh asosiasi NCI, APBD, Gradasi dan AGMARI.
                Baca Juga Lisensi LSP Teknologi Digital!</p>
                <h2 class="text-2xl font-semibold mt-4">Kementrian Pembina</h2>
                <p>Pemerintah melalui kementrian perindustrian dan kementrian kominfo juga mendata beberapa Asosiasi yang berkomitmen di dalam pengembangan industri Teknologi dan Informasi Daftar Asosiasi</p>`,
            },
        },
        {
            list_of_content: [
                {
                    title: 'Komitment Ketidak Berpiahakan',
                },
            ],
            content: {
                content: `<h1 class="text-3xl font-semibold">Komitmen Ketidakberpihakan</h1>
                <img src="${logLogo}" width="200" class="my-4" />
                <ol class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ol>`,
            },
        },
        {
            list_of_content: [
                {
                    title: 'Dasar Pemikiran',
                },
                {
                    title: 'Permasalahan',
                },
                {
                    title: 'Tujuan Penyusunan Rentra',
                },
            ],
            content: {
                content: `<h1 class="text-3xl font-semibold">Rencana Strategis</h1>
                <img src="${logLogo}" width="200" class="my-4" />
                <h2 class="text-2xl font-semibold mt-4">DASAR PEMIKIRAN</h2>
                <p>Perkembangan masyarakat dunia dengan cepat sebagai konsekuensi globalisasi mengakibatkan dunia berubah dengan cepat dan membuat batas batas negara menjadi absurd. Negara bergerak menjadi satu dengan batas batas yang yang secara fisik tampak tetapi secara secara budaya , ekonomi dan social seakan akan tanpa batas.Hal ini bisa dilihat dengan bergabungnya negara negara dalam satu zona atau kawasan menjadi menjadi satu kesatuan secara ekonomi.
                Indonesia sebagai bagian dari Negara Asean ikut serta dalam perubahan perubahan yang terjadi di kawasan tersebut. Hal ini dibuktikan dengan kesediaan Indonesia untuk bergabung dalam Masyarakat Ekonomi Asean ( MEA ) .Salah satu kesepakatan tersebut adalah adanya transfer tenaga kerja antar Negara Asean yang membuat pasar tenaga kerja semakin kompetitif antar Negara dalam kawasan tersebut .Untuk menjawab tantagan tersebut diperlukan tenaga kerja yang trampil dan tersertifikasi yang diakui secara global. LSP sebagai lembaga yang mendapat kewenangan dari BNSP untuk melakukan sertifikasi menjadi andalan untuk menyiapkan tenaga kerja yang trampil dan tersertifikasi dengan baik. Selain itu juga dalam menghadapi tantangan industri 4.0 dimana era otomatisasi dan interkonektivitas sedang ada pada puncaknya. Profesi-profesi baru mulai bermunculan seiring dengan hilangnya pekerjaan – pekerjaan digantikan oleh teknologi machine learning, kecerdasan buatan, IOT dll.</p>
                <h2 class="text-2xl font-semibold mt-4">PERMASALAHAN</h2>
                <ol class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ol>
                <h2 class="text-2xl font-semibold mt-4">TUJUAN PENYUSUNAN RENSTRA</h2>
                <p>Penyusunan rencana strategis LSP TEKNOLOGI DIGITAL bertujuan untuk mewujudkan visi dan misi LSP TEKNOLOGI DIGITAL Menjadi lembaga sertifikasi profesi bidang teknologi informasi yang professional, kredibel, akuntable dan terkini dalam pengembangan dan perlindungan sumber daya manusia di Indonesia.</p>`,
            },
        },
        {
            list_of_content: [
                {
                    title: 'Sumber Daya Manusia Pengelola LSP',
                },
                {
                    title: 'Penyelenggaraan Sertifikasi Yang Kredible',
                },
            ],
            content: {
                content: `<h1 class="text-3xl font-semibold">Program Kerja 2020</h1>
                <img src="${logLogo}" width="200" class="my-4" />
                <h2 class="text-2xl font-semibold mt-4">Sumber daya manusia pengelola LSP</h2>
                <ol class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ol>
                <h2 class="text-2xl font-semibold mt-4">Penyelenggaraan Sertifikasi yang Kredibel</h2>
                <ol class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ol>`,
            },
        },
        {
            list_of_content: [
                {
                    title: 'Perusahaan Mitra Asosiasi',
                },
                {
                    title: 'Lembaga Pelatihan',
                },
                {
                    title: 'Universitas',
                },
                {
                    title: 'Sekolah Menengah Kejuruan',
                },
            ],
            content: {
                content: `<h1 class="text-3xl font-semibold">Mitra Kerja</h1>
                <img src="${logLogo}" width="200" class="my-4" />
                <p class="mt-3">Mitra Kerja LSP Teknologi Digital</p>
                <h2 class="text-2xl font-semibold mt-4">Perusahaan Mitra Asosiasi</h2>
                <ol class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ol>
                <h2 class="text-2xl font-semibold mt-4">Lembaga Pelatihan</h2>
                <ol class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ol>
                <h2 class="text-2xl font-semibold mt-4">Universitas</h2>
                <ol class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ol>
                <h2 class="text-2xl font-semibold mt-4">Sekolah Menengah Kejuruan</h2>
                <ol class="list-decimal px-3">
                <li>Menyenggarakan sertifikasi profesi/kompetensi bidang teknologi informasi yang di akui kredibilatsnya baik di dunia pendidikan maupun industri</li>
                <li>Memiliki program kerja dan pengelolaan lembaga yang akuntabel dan dapat di pertanggungjawabkan dengan baik demi kepentingan pengembangan sumber daya manusia yang kompeten dan berakhlak mulia</li>
                <li>Memastikan skema sertifikasi menggunakan standar kompetensi terkini dan dibutuhkan oleh dunia industri</li>
                <li>Mengembangkan sumber daya manusia yang berkelanjutan dan berkesinambungan</li>
                <li>Melindungi dan mengadvokasi tenaga kerja indonesia dari permasalahan hukum dan dari tenaga kerja asing yang masuk ke indonesia</li>
                </ol>`,
            },
        },
    ];

    useEffect(() => {
        setCurrentContent({ ...content[(param.id - 1)] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.id]);

    return (
        <ProfileContext.Provider value={{ navigation, content, currentContent }}>
            {children}
        </ProfileContext.Provider>
    );
}

export const UseProfileContext = () => {
    return useContext(ProfileContext);
}