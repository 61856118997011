import { Button, Content, InputArea, InputChecked, InputDate, InputEmail, InputFile, InputNumber, InputRadio, InputSelect, InputSlider, InputText } from "../../../../components/main";
import { UseSchemaRegistrantContext } from "../../../../contexts/main/schema_certificate/registrant/SchemaRegistrantContext";

const SchemaRegistrantPage = () => {
    const { element, schema, controller, errors, currFormTab, formTab, unit, assesment, isReadDocument, onSetIsReadDocument, onSetAssesment, onSetAssesmentRadio, onSetAssesmentRadioAll, onSetController, onPrev, onSave } = UseSchemaRegistrantContext();

    const renderContent = () => {
        var content = <></>;

        switch (currFormTab.index) {
            case 0:
                content = renderIdentity();
                break;
            case 1:
                content = renderDocument();
                break;
            case 2:
                content = renderAssesment();
                break;
            default:
                content = <></>;
                break;
        }

        return content;
    }

    const renderIdentity = () => {
        return (
            <>
                <span className="text-lg font-semibold">FR.APL.01. PERMOHONAN SERTIFIKASI KOMPETENSI</span>
                <p className="text-sm">Pada bagian ini,  cantumlan data pribadi, data pendidikan formal serta data pekerjaan anda pada saat ini.</p>
                <hr className="my-2" />
                <div className="grid grid-cols-4 my-5">
                    <div>
                        <span className="font-semibold">Skema</span>
                    </div>
                    <div className="col-span-3">{schema.title}</div>
                </div>
                <hr className="my-2" />
                <div className="bg-slate-700 px-3 py-2 rounded">
                    <span className="font-medium text-white">Data Pribadi</span>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">Nama Lengkap<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <InputText error={errors.name} value={controller.name} onChange={(value) => onSetController('name', value)} placeholder="Masukan Nama Lengkap" />
                    </div>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">No. KTP/NIK/Paspor<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <InputNumber error={errors.no_identity} value={controller.no_identity} onChange={(value) => onSetController('no_identity', value)} placeholder="..." />
                    </div>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">Tempat / tgl. Lahir<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <div className="flex gap-2 items-center">
                            <InputText error={errors.place_of_birth} value={controller.place_of_birth} onChange={(value) => onSetController('place_of_birth', value)} placeholder="Masukan Tempat Lahir" />/<InputDate error={errors.date_of_birth} value={controller.date_of_birth} onChange={(value) => onSetController('date_of_birth', value)} />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">Jenis kelamin<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <InputSelect data={[{ title: "Laki-Laki", key: "Laki-Laki" }, { title: "Perempuan", key: "Perempuan" }]} error={errors.gender} value={controller.gender?.title} onChange={(value) => onSetController('gender', value)} placeholder="Jenis Kelamin" />
                    </div>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">Kebangsaan<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <InputText error={errors.nationality} value={controller.nationality} readOnly={true} onChange={(value) => onSetController('nationality', value)} placeholder="..." />
                    </div>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">Alamat rumah<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <InputArea error={errors.address} value={controller.address} onChange={(value) => onSetController('address', value)} placeholder="Alamat Lengkap Domisili atau Sesuai dengan KTP" />
                    </div>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">No. Telepon<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <InputNumber error={errors.telp} value={controller.telp} onChange={(value) => onSetController('telp', value)} placeholder="Masukan No Hp/Telp" />
                    </div>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">Email<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <InputEmail error={errors.email} value={controller.email} onChange={(value) => onSetController('email', value)} placeholder="Masukan Email" />
                    </div>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">Kualifikasi Pendidikan<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <InputText error={errors.education} value={controller.education} onChange={(value) => onSetController('education', value)} placeholder="Masukan Pendidikan Terakhir" />
                    </div>
                </div>
                <div className="bg-slate-700 px-3 py-2 rounded">
                    <span className="font-medium text-white">Data Pekerjaan</span>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">Nama Institusi / Perusahaan<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <InputText error={errors.company_name} value={controller.company_name} onChange={(value) => onSetController('company_name', value)} placeholder="Masukan Nama Instansi atau Perusahaan" />
                    </div>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">Jabatan<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <InputText error={errors.company_position} value={controller.company_position} onChange={(value) => onSetController('company_position', value)} placeholder="Jabatan di Perusahaan" />
                    </div>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">Alamat Kantor<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <InputArea error={errors.company_address} value={controller.company_address} onChange={(value) => onSetController('company_address', value)} placeholder="..." />
                    </div>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">No. Telp<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <InputNumber error={errors.company_telp} value={controller.company_telp} onChange={(value) => onSetController('company_telp', value)} placeholder="Masukan No Telp Perusahaan" />
                    </div>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">E-mail<span className="text-red-600 text-base">*</span></span>
                    </div>
                    <div className="col-span-3">
                        <InputEmail error={errors.company_email} value={controller.company_email} onChange={(value) => onSetController('company_email', value)} placeholder="Masukan Email Perusahaan" />
                    </div>
                </div>
                <div className="grid grid-cols-4 my-4">
                    <div>
                        <span className="font-semibold">Fax</span>
                    </div>
                    <div className="col-span-3">
                        <InputText error={errors.company_fax} value={controller.company_fax} onChange={(value) => onSetController('company_fax', value)} placeholder="..." />
                    </div>
                </div>
            </>
        );
    }

    const renderDocument = () => {
        return (
            <>
                <span className="text-lg font-semibold">FR.APL.01. PERMOHONAN SERTIFIKASI KOMPETENSI</span>
                <p className="text-sm">Pada bagian ini,  cantumlan data pribadi, data pendidikan formal serta data pekerjaan anda pada saat ini.</p>
                <hr className="my-2" />
                <div className="grid grid-cols-4 my-5">
                    <div>
                        <span className="font-semibold">Skema</span>
                    </div>
                    <div className="col-span-3">{schema.title}</div>
                </div>
                <hr className="my-2" />
                <div className="bg-slate-700 px-3 py-2 rounded">
                    <span className="font-medium text-white">Unit Kompetensi</span>
                </div>
                <div className="mt-2">
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="border p-3 font-semibold">No</th>
                                <th className="border p-3 font-semibold">Kode Unit</th>
                                <th className="border p-3 font-semibold">Judul Unit Kompetensi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {unit.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="border p-3 text-center">{(index + 1)}</td>
                                        <td className="border p-3 text-center">{item.code}</td>
                                        <td className="border p-3 font-medium">{item.title}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="bg-slate-700 px-3 py-2 rounded mt-10">
                    <span className="font-medium text-white">Persyaratan Dasar {'&'} Administrasi Pendukung</span>
                </div>
                <div className="mt-2">
                    <table className="w-full">
                        <tbody>
                            <tr>
                                <td className="border py-3 px-4 text-center">1.</td>
                                <td className="border py-3 px-4">Mahasiswa Jurusan Komputer, atau</td>
                            </tr>
                            <tr>
                                <td className="border py-3 px-4 text-center">2.</td>
                                <td className="border py-3 px-4">Memiliki sertifikat pelatihan sesuai ruang lingkup skema Network Administrator Muda, atau</td>
                            </tr>
                            <tr>
                                <td className="border py-3 px-4 text-center">3.</td>
                                <td className="border py-3 px-4">Pekerja dengan pengalaman minimal selama 1 tahun di bidang Network Administrator Muda</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr className="mt-8 mb-4" />
                <div>
                    <span className="text-base text-neutral-600">Upload <b>Bukti Pendidikan</b>(Ijazah atau Transkrip), <b>Bukti Pelatihan</b>(Sertifikat Pelatihan) atau <b>Bukti Pengalaman Kerja</b>(Surat Keterangan/CV)</span>
                </div>
                <div className="border border-blue-100 rounded bg-blue-50 text-blue-400 font-medium py-3 px-5 mt-3">
                    <ul>
                        <li>1. Upload dapat lebih dari 1 file. Klik Browse.</li>
                        <li>2. Ekstensi file yang di perbolehkan Image dan PDF.</li>
                        <li>3. Maksimal Ukuran File adalah 2 MB.</li>
                    </ul>
                </div>
                <div className="my-4 flex gap-3 justify-end">
                    <span>Serahkan ditempat</span>
                    <InputSlider value={isReadDocument} onChange={(value) => onSetIsReadDocument(value)} />
                </div>
                {!isReadDocument && (
                    <>
                        <div className="mt-2">
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className="pr-5">
                                            <div className="text-end flex align-top justify-end">
                                                <span className="whitespace-pre text-[]">Pasfoto<span className="text-red-600 text-base">*</span>:</span>
                                            </div>
                                        </td>
                                        <td className="w-[40%] py-1"><InputFile id="input1" value={controller.document?.photo?.file?.name} onChange={(value) => onSetController('document', { ...controller.document, photo: { title: 'Pasfoto', file: value } })} /></td>
                                    </tr>
                                    <tr>
                                        <td className="pr-5">
                                            <div className="text-end flex align-top justify-end">
                                                <span className="whitespace-pre text-[]">Identitas Pribadi (KTP / Kartu Pelajar)<span className="text-red-600 text-base">*</span>:</span>
                                            </div>
                                        </td>
                                        <td className="w-[40%] py-1"><InputFile id="input2" value={controller.document?.identity?.file?.name} onChange={(value) => onSetController('document', { ...controller.document, identity: { title: 'Identitas Pribadi (KTP / Kartu Pelajar)', file: value } })} /></td>
                                    </tr>
                                    <tr>
                                        <td className="pr-5">
                                            <div className="text-end flex align-top justify-end">
                                                <span className="whitespace-pre text-[]">Bukti Pendidikan<span className="text-red-600 text-base">*</span>:</span>
                                            </div>
                                        </td>
                                        <td className="w-[40%] py-1"><InputFile id="input3" value={controller.document?.academic?.file?.name} onChange={(value) => onSetController('document', { ...controller.document, academic: { title: 'Bukti Pendidikan', file: value } })} /></td>
                                    </tr>
                                    <tr>
                                        <td className="pr-5">
                                            <div className="text-end flex align-top justify-end">
                                                <span className="whitespace-pre text-[]">Bukti Pelatihan<span className="text-red-600 text-base">*</span>:</span>
                                            </div>
                                        </td>
                                        <td className="w-[40%] py-1"><InputFile id="input4" value={controller.document?.training?.file?.name} onChange={(value) => onSetController('document', { ...controller.document, training: { title: 'Bukti Pelatihan', file: value } })} /></td>
                                    </tr>
                                    <tr>
                                        <td className="pr-5">
                                            <div className="text-end flex align-top justify-end">
                                                <span className="whitespace-pre text-[]">Bukti Pengalaman Kerja<span className="text-red-600 text-base">*</span>:</span>
                                            </div>
                                        </td>
                                        <td className="w-[40%] py-1"><InputFile id="input5" value={controller.document?.experience?.file?.name} onChange={(value) => onSetController('document', { ...controller.document, experience: { title: 'Bukti Pengalaman Kerja', file: value } })} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="flex flex-col mt-10">
                            <span className="text-lg text-neutral-600 font-medium">BUKTI RELEVAN / PORTOFOLIO</span>
                            <span>Contoh Bukti relevan antara lain Laporan Project, Sertifikat Kompetensi, Penghargaan dan Job Sheet</span>
                        </div>
                        <div className="mt-2">
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className="pr-5">
                                            <div className="text-end flex align-top justify-end">
                                                <span className="whitespace-pre">Portofolio:</span>
                                            </div>
                                        </td>
                                        <td className="w-[40%] py-1"><InputFile id="input6" value={controller.document?.portfolio?.file?.name} onChange={(value) => onSetController('document', { ...controller.document, portfolio: { title: 'Portofolio', file: value } })} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </>
        );
    }

    const renderAssesment = () => {
        var no_assesment = 1;
        return (
            <>
                <span className="text-lg font-semibold">FR.APL.01. ASSESMENT MANDIRI</span>
                <p className="text-sm">Pastikan anda kompeten sesuai dengan elemen dan kuk yang ada pada setiap unit-unit kompetensi berikuti ini. K(Kompeten) dan BK(Belum Kompeten)K .Pilih Bukti Kompetensi yang relevan dari file yang telah anda upload di tahap sebelumnya.</p>
                <hr className="my-2" />
                <div className="grid grid-cols-4 my-5">
                    <div>
                        <span className="font-semibold">Skema</span>
                    </div>
                    <div className="col-span-3">{schema.title}</div>
                </div>
                <hr className="my-2" />
                <div className="bg-slate-700 px-3 py-2 rounded">
                    <span className="font-medium text-white">Skema Assesment</span>
                </div>
                <div className="mt-2">
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="border p-3 font-semibold">No</th>
                                <th className="border p-3 font-semibold">Kode Unit</th>
                                <th className="border p-3 font-semibold">Judul Unit Kompetensi / Elemen Kompetensi / Kriteria Unjuk Kerja(KUK)</th>
                                <th className="border p-3 font-semibold">
                                    <div className="flex flex-col">
                                        <span>K</span>
                                        <InputChecked checked={assesment.every((x) => x.k)} onChange={(value) => onSetAssesmentRadioAll('k', 'bk', value)} />
                                    </div>
                                </th>
                                <th className="border p-3 font-semibold">
                                    <div className="flex flex-col">
                                        <span>BK</span>
                                        <InputChecked checked={assesment.every((x) => x.bk)} onChange={(value) => onSetAssesmentRadioAll('bk', 'k', value)} />
                                    </div>
                                </th>
                                <th className="border p-3 font-semibold">Bukti yang relevan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {assesment.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="border p-3 text-center">{(item.is_heading === 1 && (assesment[index - 1]?.is_heading !== 1) && assesment[index - 1]?.schema_unit_code !== item.schema_unit_code) && no_assesment++}</td>
                                        <td className="border p-3 text-center">{(item.is_heading === 1 && (assesment[index - 1]?.is_heading !== 1) && assesment[index - 1]?.schema_unit_code !== item.schema_unit_code) && item.schema_unit_code}</td>
                                        <td className={`border p-3 text-start ${item.is_heading === 1 ? 'font-semibold' : ''}`}>{item.title}</td>
                                        <td className="border p-3 text-center">{(item.is_heading !== 1) && <InputRadio checked={item.k} id={`data-${index}`} name={`data-${index}`} onChange={() => onSetAssesmentRadio('k', 'bk', index)} />}</td>
                                        <td className="border p-3 text-center">{(item.is_heading !== 1) && <InputRadio checked={item.bk} id={`data-${index}`} name={`data-${index}`} onChange={() => onSetAssesmentRadio('bk', 'k', index)} />}</td>
                                        <td className="border p-3 text-center">{(item.is_heading !== 1) && <div className="w-[10rem] max-w-[10rem]"><InputSelect className="whitespace-pre overflow-hidden" value={assesment[index].proof?.title} data={controller.document_array} onChange={(value) => onSetAssesment('proof', index, value)} placeholder={"Belum Diisi"} /></div>}</td>
                                    </tr>
                                );
                            })}
                            {assesment?.length === 0 && <tr><td className="border p-3 text-center" colSpan={6}><div className="">Tidak ada data</div></td></tr>}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    const renderButton = () => {
        return (
            <div className="flex justify-between gap-3 mt-10">
                {currFormTab.index === 0 && <Button></Button>}
                {currFormTab.index > 0 && <Button className="bg-slate-700 text-white" onClick={() => onPrev()}>Kembali</Button>}
                {currFormTab.index < (formTab.length - 1) && <Button className="bg-slate-700 text-white" onClick={() => onSave()}>Selanjutnya</Button>}
                {currFormTab.index === (formTab.length - 1) && <Button className="bg-slate-700 text-white" onClick={() => onSave()}>Selesai {'&'} Kirim</Button>}
            </div>
        );
    }

    return (
        <Content element={element} nav={false}>
            <div className="flex justify-center mt-4 sm-zoom-50">
                <div className="flex flex-col w-full max-w-[1140px] px-3">
                    {/* Navigation */}
                    <div className="w-full flex justify-center gap-5 mt-3 mb-10">
                        <div className="flex w-full px-20">
                            {formTab.map((item, index) => {
                                return (
                                    <div key={index} className="flex flex-col grow">
                                        <div className="flex gap-2 items-center grow">
                                            {index !== 0 && <hr className="grow" />}
                                            <div className="relative flex justify-center">
                                                <div className={`w-3 h-3 p-2 rounded-full ${item.is_active ? 'bg-green-100 border border-green-500' : 'bg-red-100 border border-red-500'}`} />
                                                <div className="absolute top-5">
                                                    <span className="whitespace-pre">{item.title}</span>
                                                </div>
                                            </div>
                                            {index !== (formTab.length - 1) && <hr className="grow" />}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {/* Content */}
                    <div className="w-full flex justify-center gap-5">
                        <div className="border p-5 rounded grow">
                            {renderContent()}
                            {renderButton()}
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    );
}

export default SchemaRegistrantPage;